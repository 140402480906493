import { Breadcrumb, Col, Container, Row, Form } from "react-bootstrap";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import { Home, DadosRight, DadosLeft, CardsNumeros, BotoesNavegacao } from "./styles";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";
import { useEffect } from "react";
import { GraficoGChart } from "../../components/GraficoGChart"
import { ComponenteAceiteCampanha } from "../../components/ComponenteAceiteCampanha"
import { GameficacaoDesempenho } from "../../components/GameficacaoDesempenho"
import { GraficoVendaLoja } from '../../components/GraficoVendaLoja'

export function MeuDesempenhoVendas({ user }) {
  const [extratoGeralLista, setExtratoGeralLista] = useState();
  const [erroExtrato, setErroextrato] = useState(false);
  const [dadosDesempenho, setDadosDesempenho] = useState([]);
  const [dadosDesempenhoVendas, setDadosDesempenhoVendas] = useState();
  const [dadosDesempenhoCampanha, setDadosDesempenhoCampanha] = useState();
  const [dadosDesempenhoTreinamento, setDadosDesempenhoTreinamento] = useState();
  // const [isHorizontal, setIsHorizontal] = React.useState(false);
  const [abaAtiva, setAbaAtiva] = useState(0);
  const [abaAtivaGrafico, setAbaAtivaGrafico] = useState(1);

  var today = new Date();
  var yyyy = today.getFullYear();


  useEffect(() => {
    // const handleResize = () => {
    //   setIsHorizontal(window.innerWidth > 768); // Verifica se a largura da tela é maior que 768 pixels
    // };
    // handleResize(); // Verifica o estado inicial
    // window.addEventListener('resize', handleResize);
    MeuDesempenhoDadosVendas(yyyy)
    MeuDesempenhoDadosCampanha(yyyy)
  }, []);


  // const options = {
  //   title: 'Monthly Sales',
  //   hAxis: {
  //     title: 'Month',
  //   },
  //   vAxis: {
  //     title: 'Salessss',
  //   },
  // };



  const convertDate = (date) => {
    var convertdate = new Date(date);

    var ddconvertdate = String(convertdate.getDate()).padStart(2, "0");
    var mmconvertdate = String(convertdate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyyconvertdate = String(convertdate.getFullYear()).padStart(4, "0");

    convertdate = ddconvertdate + "/" + mmconvertdate + "/" + yyyyconvertdate;

    return convertdate;
  };



  const MeuDesempenhoDadosCampanha = (e) => {
    api
      .get(rotasApi.MeuDesempenhoCampanha + `?ano=${e}`)
      .then((result) => {
        setDadosDesempenho(result.data.campanhas)
        setDadosDesempenhoCampanha(result.data.campanhas)
        setExtratoGeralLista(result.data.campanhas.meusPremios)
      }).catch((error) => {
        console.log('error obter desempenho campanha')
      })
  };

  const MeuDesempenhoDadosVendas = (e) => {
    api
      .get(rotasApi.MeuDesempenhoDadosVendas + `?ano=${e}`)
      .then((result) => {

        const getMonthName = (monthNumber) => {
          const monthNames = [
            'Jan', 'Fev', 'Mar', 'Abr',
            'Mai', 'Jun', 'Jul', 'Ago',
            'Set', 'Out', 'Nov', 'Dez'
          ];
          return monthNames[monthNumber - 1];
        }
        let data = result.data.abaVendas.meses.map((item) => ({
          label: getMonthName(item.mes),
          value: item.qtdSuperTop,
        }));
        let chartData = [['Mes', 'Valor'], ...data.map(({ label, value }) => [label, value])];
        result.data.abaVendas.meses = chartData

        setDadosDesempenhoVendas(result.data.abaVendas)
        setAbaAtiva(1)
        setAbaAtivaGrafico(1)

      }).catch((error) => {
        console.log('error obter desempenho campanha')
      })
  };

  const MeuDesempenhoDadosTreinamento = (e) => {
    api
      .get(rotasApi.MeuDesempenhoDadosTreinamento + `?ano=${e}`)
      .then((result) => {
        preparaDados(result)
      }).catch((error) => {
        console.log('error obter desempenho Treinamento')
      })
  };

  const preparaDados = (result) => {
    const getMonthName = (monthNumber) => {
      const monthNames = [
        'Jan', 'Fev', 'Mar', 'Abr',
        'Mai', 'Jun', 'Jul', 'Ago',
        'Set', 'Out', 'Nov', 'Dez'
      ];
      return monthNames[monthNumber - 1];
    }
    const getBarColor = (value) => {
      return value > 1 ? '#9ED530' : '#F58420';
    };
    let data = result.data.abaTreinamentoDto.meses.map((item) => ({
      label: getMonthName(item.mes),
      value: item.qtdTreinamentosRealizados,
    }));
    let chartData = [['Mes', 'Treinamento'], ...data.map(({ label, value }) => [label, value])];
    result.data.abaTreinamentoDto.meses = chartData
    setDadosDesempenhoTreinamento(result.data.abaTreinamentoDto)
    setAbaAtiva(2)
  }

  const mudarAba = (e) => {
    setAbaAtiva(e)
    if (e === 2) {
      MeuDesempenhoDadosTreinamento(yyyy - 1)
    }
  }

  const mudarAbaGrafico = (e) => {
    setAbaAtivaGrafico(e)
  }


  return (
    <>
      <Home className="meudesempenho-bf">
        <NavBar user={user} />
        <Container>

          <Breadcrumb className="mt-3">
            <Link className="breadcrumb-item" to="/home">Home</Link>
            <Breadcrumb.Item active>Meu Desempenho</Breadcrumb.Item>
          </Breadcrumb>

          <hr className="mt-3" />
          <Row className="mt-5">
            <BotoesNavegacao>
              <div className="botoes-navegacao">
                <a href='#' className={abaAtiva === 1 ? 'active btn-navegacao' : ' btn-navegacao'} onClick={(e) => { mudarAba(1) }}>Vendas</a>
                <a href='#' className={abaAtiva === 2 ? 'active btn-navegacao' : ' btn-navegacao'} onClick={(e) => { mudarAba(2) }}>Treinamentos</a>
                <a href='#' className={abaAtiva === 3 ? 'active btn-navegacao' : ' btn-navegacao'} onClick={(e) => { mudarAba(3) }}>Campanhas</a>
                {user.lojas[0].rede.nome !== 'J MAHFUZ LTDA'
                  && user.lojas[0].rede.nome !== 'CONDOR SUPER CENTER LTDA'
                  && user.lojas[0].rede.nome !== 'GLOBAL DISTRIBUIDORA DE BENS DE CONSUMO LTDA'
                  && user.lojas[0].rede.nome !== 'RAMIRO CAMPELO COMERCIO DE UTILIDADES LTDA'
                  && user.lojas[0].rede.nome !== 'MASTER ELETRÔNICA DE BRINQ. LTDA.'
                  && user.lojas[0].rede.nome !== 'MILLENA COMERCIO VAREJISTA DE MÓVEIS E ELETRO EIRELI'
                  && user.lojas[0].rede.nome !== 'ELETROMAR MOVEIS ELETRODOMESTICOS LTDA'
                  ?
                  <a href='#' className={abaAtiva === 4 ? 'active btn-navegacao' : ' btn-navegacao'} onClick={(e) => { mudarAba(4) }}>Desafio +Top</a> : ('')}
              </div>
            </BotoesNavegacao>

            <Col className="mt-0 mb-4" md="4">
              <DadosLeft>
                {abaAtiva === 1 ? <CardsNumeros>
                  <div className="d-flex justify-content-between w-100">
                    <div className="card-item">
                      <div><p>Total de vendas realizadas</p></div>
                      <div>
                        <h1>{dadosDesempenhoVendas ? dadosDesempenhoVendas.totalVendas : ''}</h1>
                      </div>
                    </div>
                    <div className="card-item">
                      <div><p>Média de vendas mensal</p></div>
                      <div><h1>{dadosDesempenhoVendas ? Math.floor(dadosDesempenhoVendas.mediaMensal) : ('')}</h1></div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-100">
                    <div className="card-item">
                      <div><p>Quantidade produtos supertops</p></div>
                      <div><h1>{dadosDesempenhoVendas ? dadosDesempenhoVendas.qtdSuperTop : ''}</h1></div>
                    </div>
                    <div className="card-item">
                      <div><p>Quantidade produtos participantes</p></div>
                      <div><h1>{dadosDesempenhoVendas ? dadosDesempenhoVendas.qtdParticipante : ''}</h1></div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-100">
                    <div className="card-item">
                      <div><p>Pontos creditados</p></div>
                      <div><h1>{dadosDesempenhoVendas ? dadosDesempenhoVendas.totalPontos : ''}</h1></div>
                    </div>
                    <div className="card-item">
                      <div><p>Média de pontos creditados mensal</p></div>
                      <div><h1>{dadosDesempenhoVendas ? Math.floor(dadosDesempenhoVendas.mediaMensalPontos) : ('')}</h1></div>
                    </div>
                  </div>
                </CardsNumeros> : abaAtiva === 2 ? <CardsNumeros>
                  <div className="d-flex justify-content-between w-100">
                    <div className="card-item">
                      <div><p>Total de Treinamentos</p></div>
                      <div>
                        <h1>{dadosDesempenhoTreinamento ? dadosDesempenhoTreinamento.totalTreinamentos : ('')}</h1>
                      </div>
                    </div>
                    <div className="card-item">
                      <div><p>% de treinamentos realizados</p></div>
                      <div><h1>{dadosDesempenhoTreinamento ? Math.floor(dadosDesempenhoTreinamento.porcentagemRealizado) : ('')}</h1></div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-100">
                    <div className="card-item">
                      <div><p>Total de treinamentos realizados</p></div>
                      <div><h1>{dadosDesempenhoTreinamento ? dadosDesempenhoTreinamento.totalRealizado : ('')}</h1></div>
                    </div>
                    <div className="card-item">
                      <div><p>Total de treinamentos não realizados</p></div>
                      <div><h1>{dadosDesempenhoTreinamento ? dadosDesempenhoTreinamento.totalNaoRealizado : ('')}</h1></div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-100">
                    <div className="card-item">
                      <div><p>Pontos recebidos</p></div>
                      <div><h1>{dadosDesempenhoTreinamento ? dadosDesempenhoTreinamento.pontosRecebidos : ('')}</h1></div>
                    </div>
                    <div className="card-item">
                      <div><p>Pontuação que deixou de ganhar</p></div>
                      <div><h1>{dadosDesempenhoTreinamento ? dadosDesempenhoTreinamento.pontosNaoRecebidos : ('')}</h1></div>
                    </div>
                  </div>
                </CardsNumeros> : abaAtiva === 3 ? <CardsNumeros>
                  <div className="d-flex justify-content-between w-100">
                    <div className="card-item">
                      <div><p>Total de campanhas disponíveis</p></div>
                      <div>
                        <h1>{dadosDesempenho.totalCampanhas}</h1>
                      </div>
                    </div>
                    <div className="card-item">
                      <div><p>Total de participação em campanhas</p></div>
                      <div><h1>{dadosDesempenho.totalAceites}</h1></div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-100 container-card-item">
                    <div className="card-item">
                      <div><p>Total de não participação em campanhas</p></div>
                      <div><h1>{dadosDesempenho.totalNaoAceites}</h1></div>
                    </div>
                  </div>
                </CardsNumeros> : abaAtiva === 4 ? (<CardsNumeros>
                  <div className="d-flex justify-content-between w-100">
                    <div className="card-item">
                      <div><p>Total de vendas realizadas</p></div>
                      <div>
                        <h1>{dadosDesempenhoVendas.totalVendas ? dadosDesempenhoVendas.totalVendas : ''}</h1>
                      </div>
                    </div>
                    <div className="card-item">
                      <div><p>Média de vendas mensal</p></div>
                      <div><h1>{Math.floor(dadosDesempenhoVendas.mediaMensal)}</h1></div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-100">
                    <div className="card-item">
                      <div><p>Quantidade produtos supertops</p></div>
                      <div><h1>{dadosDesempenhoVendas.qtdSuperTop}</h1></div>
                    </div>
                    <div className="card-item">
                      <div><p>Quantidade produtos participantes</p></div>
                      <div><h1>{dadosDesempenhoVendas.qtdParticipante}</h1></div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-100">
                    <div className="mudarAbaGrafico card-item">
                      <div><p>Pontos creditados</p></div>
                      <div><h1>{dadosDesempenhoVendas.totalPontos}</h1></div>
                    </div>
                    <div className="card-item">
                      <div><p>Média de pontos creditados mensal</p></div>
                      <div><h1>{Math.floor(dadosDesempenhoVendas.mediaMensalPontos)}</h1></div>
                    </div>
                  </div>
                </CardsNumeros>) : ('')}
              </DadosLeft>
            </Col>
            <Col className="mb-4" md="8">
              <DadosRight>
                <div className="grafico">
                  {abaAtiva === 1 ?
                    <div className="container-menu-dados">
                      <div href='#' className={abaAtivaGrafico === 1 ? 'active btn-dados-grafico' : ' btn-dados-grafico'} onClick={(e) => { mudarAbaGrafico(1) }}>Média vendas loja vs Venda realizada</div>
                      <div href='#' className={abaAtivaGrafico === 2 ? 'active btn-dados-grafico' : ' btn-dados-grafico'} onClick={(e) => { mudarAbaGrafico(2) }}>Evolução de vendas</div>
                      <div href='#' className={abaAtivaGrafico === 3 ? 'active btn-dados-grafico' : ' btn-dados-grafico'} onClick={(e) => { mudarAbaGrafico(3) }}>Distribuição de pontos</div>
                    </div> : abaAtiva === 2 ?
                      <div className="container-menu-dados">
                        <div href='#' className={abaAtivaGrafico === 1 ? 'active btn-dados-grafico' : ' btn-dados-grafico'} onClick={(e) => { mudarAbaGrafico(1) }}>Treinamentos realizados</div>
                        <div href='#' className={abaAtivaGrafico === 2 ? 'active btn-dados-grafico' : ' btn-dados-grafico'} onClick={(e) => { mudarAbaGrafico(2) }}>Pontuação treinamento</div>
                      </div> : abaAtiva === 3 ? <div className="container-menu-dados">
                        <div href='#' className={abaAtivaGrafico === 1 ? ' btn-dados-grafico' : ' btn-dados-grafico'} onClick={(e) => { mudarAbaGrafico(1) }}>Aceite campanha realizadas</div>
                        {/*<div href='#' className={abaAtivaGrafico === 2 ? 'active btn-dados-grafico' : ' btn-dados-grafico'} onClick={(e) => { mudarAbaGrafico(2) }}>Evolução de vendas</div>
                        <div href='#' className={abaAtivaGrafico === 3 ? 'active btn-dados-grafico' : ' btn-dados-grafico'} onClick={(e) => { mudarAbaGrafico(3) }}>Distribuição de pontos</div> */}
                      </div> : abaAtiva === 4 ? <div className="container-menu-dados">
                        <div href='#' className={abaAtivaGrafico === 1 ? 'active btn-dados-grafico' : ' btn-dados-grafico'} onClick={(e) => { mudarAbaGrafico(1) }}>Geral</div>
                        <div href='#' className={abaAtivaGrafico === 2 ? 'active btn-dados-grafico' : ' btn-dados-grafico'} onClick={(e) => { mudarAbaGrafico(2) }}>Vendas vs Meta</div>
                      </div> : ('')
                  }
                  {abaAtiva === 2 && abaAtivaGrafico === 1 ? <div className="d-flex justify-content-end">
                    <Form.Select className="select-ano" aria-label="Ano" onChange={(e) => { MeuDesempenhoDadosTreinamento(e.target.value) }}>
                      <option value={yyyy}>{yyyy}</option>
                      <option value={yyyy - 1}>{yyyy - 1}</option>
                    </Form.Select>
                  </div> : ''}

                  {abaAtiva === 3 ? <div className="select-filtro-ano">
                    <Form.Select className="select-ano" aria-label="Ano" onChange={(e) => { MeuDesempenhoDadosCampanha(e.target.value) }}>
                      <option value={yyyy}>{yyyy}</option>
                      <option value={yyyy - 1}>{yyyy - 1}</option>
                    </Form.Select></div> : ('')}
                  {abaAtiva === 1 ? <GraficoVendaLoja tipoGrafico={abaAtivaGrafico} /> : ('')}

                  {(abaAtiva === 2) && dadosDesempenhoTreinamento ? <GraficoGChart dadosdata={dadosDesempenhoTreinamento} abaAtiva={abaAtivaGrafico} /> : ('')}

                  {(abaAtiva === 3) && dadosDesempenhoCampanha ?
                    <ComponenteAceiteCampanha dados={dadosDesempenhoCampanha} /> : ('')}
                  {abaAtiva === 4 ? <GameficacaoDesempenho tipoGrafico={abaAtivaGrafico} /> : ('')}


                </div>
                <div className="tabela-container">
                  <h5>Meus prêmios</h5>
                  <div className="tabela-resultado">
                    {erroExtrato ? (
                      <div>
                        <p>{erroExtrato}</p>
                      </div>
                    ) : (
                      <>
                        <div className="topo-tabela">
                          <div className="w-75">Premio</div>
                          <div className="w-25">Pontuação</div>
                          <div className="w-25">Data</div>
                        </div>
                        <div className="p-1 ps-0 bg-white barra">
                          <div className="corpo-tabela">
                            {extratoGeralLista ? extratoGeralLista.length > 0 ? extratoGeralLista.map(
                              (
                                {
                                  premio,
                                  data,
                                  pontuacao
                                },
                                index
                              ) => (
                                <div className="linha-tabela" key={index}>
                                  <div className="w-75">{premio}</div>
                                  <div className="w-25">
                                    {pontuacao}
                                  </div>
                                  <div className="w-25">
                                    {convertDate(data)}
                                  </div>
                                </div>
                              )
                            ) : (<div className="linha-tabela">
                              <div className="w-100 text-center">Não hã dados a serem exibidos</div>
                            </div>) : ('')}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </DadosRight>
            </Col>
          </Row>
        </Container>
        <Footer className="m-0 p-0"></Footer>
      </Home >

    </>
  );
}

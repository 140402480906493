import React, { useState, useEffect } from "react";
import { ProgressoNivel, Etapas } from "./styles";
import Top from "../../images/img/icon-ranking-top-ativo.svg";
import Topper from "../../images/img/icon-ranking-topper-inativo.svg";
import TopperAtivo from "../../images/img/icon-nivel-topper-active.svg";
import Topissimo from "../../images/img/icon-ranking-topissimo-inativo.svg";
import TopissimoAtivo from "../../images/img/icon-nivel-topissimo-active.svg";
import TopGalaxias from "../../images/img/icon-ranking-megatop-inativo.svg";
import TopGalaxiasAtivo from "../../images/img/icon-nivel-topgalaxias-active.svg";
import IconTaskChecked from "../../images/img/icon-task-checked.svg"
import api from "../../services/api";
import { rotasApi } from "../../services/rotasApi";
import images from "../../images/images";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export function ConquistasUsuarioHome({ user, childToParent }) {

	const [nivelUsuarioVendedor, setNivelUsuarioVendedor] = useState('0%')
	const [nivelPrincipalUsuario, setNivelPrincipalUsuario] = useState(1)
	const [expandMenuConquistas, setExpandMenuConquistas] = useState(false)
	const [ConquistasUsuario, SetConquistasUsuario] = useState({
		conquistaTarefas: [
			{
				descricao: "",
				icone: "",
				mesesAlcancados: 0,
				titulo: "",
			},
			{
				descricao: "",
				icone: "",
				mesesAlcancados: 0,
				titulo: "",
			},
			{
				descricao: "",
				icone: "",
				mesesAlcancados: 0,
				titulo: "",
			},
		]
	});

	const VerificarNivelVendedor = (e) => {
		if (e === 0) {
			setNivelUsuarioVendedor(`0%`)
		}
		else if (e === 1) {
			setNivelUsuarioVendedor(`33%`)
		} else if (e === 2) {
			setNivelUsuarioVendedor(`66%`)
		} else if (e === 3) {
			setNivelUsuarioVendedor(`100%`)
		}
	}

	const ObterConquista = (id) => {

		api
			.post(rotasApi.ObterConquistas)
			.then((result) => {
				SetConquistasUsuario(result.data.conquistas_usuario)
				VerificarNivelVendedor(result.data.conquistas_usuario.nivelUsuario)
				setNivelPrincipalUsuario(result.data.conquistas_usuario.nivelUsuario)
				childToParent(result.data.conquistas_usuario.nivelUsuario)

			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	useEffect(() => {
		ObterConquista()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const expandMenu = () => {
		setExpandMenuConquistas(!expandMenuConquistas)
	}

	const popover = (
		<Popover id="popover-basic" style={{ minWidth: "700px" }}>
			<Popover.Header as="h3">{user.cargo.descricao}</Popover.Header>
			<Popover.Body className="popover-body">
				O Desafio +top é um jogo onde sua performance te leva além e traz reconhecimento para o seu desempenho nas métricas do +top.<br /><br />
				Concluindo as missões mensais, a cada 3 meses você sobe de patente e ganha uma raspadinha com prêmios surpresa, além de se tornar elegível para o ranking final da Campanha Anual.
				Todos começam o jogo na patente Explorador e podem escalar, trimestralmente, para as patentes Visionário, Realizador, podendo alcançar a patente Conquistador, mais alta do jogo, em apenas 9 meses.
				Para subir de patente é só completar as missões mensais durante 3 meses.
				<br />
				<br />
				{user.cargo.descricao === "VENDEDOR" ? <span>Acesso Mensal: você precisa acessar a Plataforma ao menos 2 vezes por mês (Em dias diferentes).<br />
					Produto Supertop: você precisa bater sua meta de vendas de produtos Supertops.<br />
					Produto Participante: você precisa bater sua meta de vendas dos produtos participantes.<br />
					Treinamentos: você precisa ser aprovado nos 2 treinamentos mensais.</span> : ('')
				}
				{user.cargo.descricao === "GERENTE" ? <span>Acesso Mensal: você precisa acessar a Plataforma ao menos 2 vezes por mês (Em dias diferentes).<br />
					Produto Supertop: você precisa bater a meta da loja de vendas de produtos Supertops.<br />
					Produto Participante: você precisa bater a meta da loja de vendas dos produtos participantes.<br />
					Treinamentos: você precisa que 80% da equipe da sua loja conclua os 2 treinamentos mensais.</span> : ('')
				}
				{user.cargo.descricao === "GERENTE REGIONAL" ? <span>Acesso Mensal: você precisa acessar a Plataforma ao menos 2 vezes por mês (Em dias diferentes).<br />
					Produto Supertop: você precisa bater a meta de vendas de produtos Supertops nas suas lojas.<br />
					Produto Participante: você precisa bater a meta de vendas dos produtos participantes nas suas lojas.<br />
					Treinamentos: você precisa que 80% da equipe da sua loja conclua os 2 treinamentos mensais.<br /></span> : ('')
				}
				{user.cargo.descricao === "GESTOR DA INFORMACAO" ? <span>Acesso Mensal: você precisa acessar a Plataforma ao menos 2 vezes por mês (Em dias diferentes).<br />
					Envio de bases vendas: você precisa enviar a base de vendas até o 5º dia útil do mês.<br />
					Envio de bases hierarquia: você precisa enviar a base de hierarquia até o 5º dia útil do mês.<br />
					Base ativa: você precisa de 80% da equipe cadastrada ativa no programa todos os meses.<br /></span> : ('')
				}
			</Popover.Body>
		</Popover>
	);

	const popoverMobile = (
		<Popover id="popover-basic" >
			<Popover.Header as="h3">{user.cargo.descricao}</Popover.Header>
			<Popover.Body className="popover-body">
				O Desafio +top é um jogo onde sua performance te leva além e traz reconhecimento para o seu desempenho nas métricas do +top.<br /><br />
				Concluindo as missões mensais, a cada 3 meses você sobe de patente e ganha uma raspadinha com prêmios surpresa, além de se tornar elegível para o ranking final da Campanha Anual.
				Todos começam o jogo na patente Explorador e podem escalar, trimestralmente, para as patentes Visionário, Realizador, podendo alcançar a patente Conquistador, mais alta do jogo, em apenas 9 meses.
				Para subir de patente é só completar as missões mensais durante 3 meses.
				<br />
				<br />
				{user.cargo.descricao === "VENDEDOR" ? <span>Acesso Mensal: você precisa acessar a Plataforma ao menos 2 vezes por mês (Em dias diferentes).
					Produto Supertop: você precisa bater sua meta de vendas de produtos Supertops.
					Produto Participante: você precisa bater sua meta de vendas dos produtos participantes.
					Treinamentos: você precisa ser aprovado nos 2 treinamentos mensais.</span> : ('')
				}
				{user.cargo.descricao === "GERENTE" ? <span>Acesso Mensal: você precisa acessar a Plataforma ao menos 2 vezes por mês (Em dias diferentes).
					Produto Supertop: você precisa bater a meta da loja de vendas de produtos Supertops.
					Produto Participante: você precisa bater a meta da loja de vendas dos produtos participantes.
					Treinamentos: você precisa que 80% da equipe da sua loja conclua os 2 treinamentos mensais.</span> : ('')
				}
				{user.cargo.descricao === "GERENTE REGIONAL" ? <span>Acesso Mensal: você precisa acessar a Plataforma ao menos 2 vezes por mês (Em dias diferentes).
					Produto Supertop: você precisa bater a meta de vendas de produtos Supertops nas suas lojas.
					Produto Participante: você precisa bater a meta de vendas dos produtos participantes nas suas lojas.
					Treinamentos: você precisa que 80% da equipe da sua loja conclua os 2 treinamentos mensais.</span> : ('')
				}
				{user.cargo.descricao === "GESTOR DA INFORMACAO" ? <span>Acesso Mensal: você precisa acessar a Plataforma ao menos 2 vezes por mês (Em dias diferentes).
					Envio de bases vendas: você precisa enviar a base de vendas até o 5º dia útil do mês.
					Envio de bases hierarquia: você precisa enviar a base de hierarquia até o 5º dia útil do mês.
					Base ativa: você precisa de 80% da equipe cadastrada ativa no programa todos os meses.</span> : ('')
				}
			</Popover.Body>
		</Popover>
	);

	return (
		<>
			<ProgressoNivel className="rounded p-0">
				<img src={images.SeloTask} alt="" className="selo-missoes" />
				<div className="nivel-atual-user">
					<OverlayTrigger
						placement="bottom-start"
						overlay={popover}
					>
						<i className="bi bi-question-circle icon-trigger-desk" style={{ position: "absolute", top: "13px", right: "54px", }}></i>
					</OverlayTrigger>
					<OverlayTrigger
						placement="bottom-start"
						overlay={popoverMobile}
					>
						<i className="bi bi-question-circle icon-trigger-mob" style={{ position: "absolute", top: "14px", left: "200px", }}></i>
					</OverlayTrigger>
					{user.cargo.descricao !== 'GESTOR DA INFORMACAO' ?
						<span className="title-nivel-user">
							<h5>Desafio +top</h5>

							Você está no nível:
							{nivelPrincipalUsuario === 1 ? <span className="nivel-usuario"  >  Explorador</span>
								:
								nivelPrincipalUsuario === 2 ? <span className="nivel-usuario">  Visionário</span>
									:
									nivelPrincipalUsuario === 3 ? <span className="nivel-usuario">  Realizador</span>
										:
										nivelPrincipalUsuario === 4 ? <span className="nivel-usuario">  Consquistador</span> : ('')}
						</span> : <div className="d-flex justify-content-between mb-2">
							{/* <h6>GI 5 estrelas</h6> */}
							<p className="title-nivel-user">
								Você está no nível:
								{nivelPrincipalUsuario === 0 ? <span className="nivel-usuario gi">1 Estrela</span>
									:
									nivelPrincipalUsuario === 1 ? <span className="nivel-usuario gi">2 Estrelas</span>
										:
										nivelPrincipalUsuario === 2 ? <span className="nivel-usuario gi">3 Estrelas</span>
											:
											nivelPrincipalUsuario === 3 ? <span className="nivel-usuario gi">4 Estrelas</span> :

												nivelPrincipalUsuario === 4 ? <span className="nivel-usuario gi">5 Estrelas</span> :
													('')}
							</p>
						</div>}
					<div className="eight columns progress-container">
						{nivelUsuarioVendedor?
						<div className="progressBarcontainer">
							<div
								className="progressBarValue"
								style={{
									width:`${nivelUsuarioVendedor}`
								}}
							></div> ${nivelUsuarioVendedor}
						</div>:('')}

						{user.cargo.descricao !== 'GESTOR DA INFORMACAO' ?
							<div className="niveis-progressbar">
								<div>
									<img
										src={Top}
										alt="Ícone Ranking TOP"
										className="icone-raking"
									/>
								</div>
								<div className="nivel-alcancado">
									{nivelPrincipalUsuario >= 2 ?

										<img
											src={TopperAtivo}
											alt="Ícone Ranking TOPPER"
											className="icone-raking"
										/> : <img
											src={Topper}
											alt="Ícone Ranking TOPPER"
											className="icone-raking"
										/>
									}
								</div>
								<div>
									{nivelPrincipalUsuario >= 3 ?
										<img
											src={TopissimoAtivo}
											alt="Ícone Ranking TOPÍSSIMO"
											className="icone-raking"
										/> :
										<img
											src={Topissimo}
											alt="Ícone Ranking TOPÍSSIMO"
											className="icone-raking"
										/>
									}
								</div>
								<div>
									{nivelPrincipalUsuario >= 4 ?
										<img
											src={TopGalaxiasAtivo}
											alt="Ícone Ranking MEGA TOP"
											className="icone-raking"
										/> :
										<img
											src={TopGalaxias}
											alt="Ícone Ranking MEGA TOP"
											className="icone-raking"
										/>}
									{/*  */}
								</div>
							</div> : 
							<div className="niveis-progressbar">
								<div>
									<img
										src={TopperAtivo}
										alt="Ícone Ranking TOP"
										className="icone-raking"
									/>
								</div>
								<div className="nivel-alcancado">
									{nivelPrincipalUsuario >= 1 ?

										<img
											src={TopperAtivo}
											alt="Ícone Ranking TOPPER"
											className="icone-raking"
										/> : <img
											src={Topper}
											alt="Ícone Ranking TOPPER"
											className="icone-raking"
										/>
									}
								</div>
								<div>
									{nivelPrincipalUsuario >= 2 ?
										<img
											src={TopperAtivo}
											alt="Ícone Ranking TOPÍSSIMO"
											className="icone-raking"
										/> :
										<img
											src={Topper}
											alt="Ícone Ranking TOPÍSSIMO"
											className="icone-raking"
										/>
									}
								</div>
								<div>
									{nivelPrincipalUsuario >= 3 ?
										<img
											src={TopperAtivo}
											alt="Ícone Ranking MEGA TOP"
											className="icone-raking"
										/> :
										<img
											src={Topper}
											alt="Ícone Ranking MEGA TOP"
											className="icone-raking"
										/>}
								</div>
								<div>
									{nivelPrincipalUsuario >= 4 ?
										<img
											src={TopperAtivo}
											alt="Ícone Ranking MEGA TOP"
											className="icone-raking"
										/> :
										<img
											src={Topper}
											alt="Ícone Ranking MEGA TOP"
											className="icone-raking"
										/>}
								</div>
							</div>}
						{user.cargo.descricao !== 'GESTOR DA INFORMACAO' ? <div className="niveis-progressbar mt-4">
							<p style={{ marginLeft: "-17px", color: "#9ed530" }} className="active">Explorador</p>
							<p>Visionário</p>
							<p>Realizador</p>
							<p>Conquistador</p>
						</div> : <p className="text-conquista-gi">Chegue ao nível 5 estrelas,<br /> concluindo os desafios!</p>}
					</div>
				</div>
				<p className="aviso-missoes pt-3 pb-3">Complete as metas abaixo para avançar de nível <br />
					e concorra a prêmios na raspadinha +top.
				</p>
				<div className="arrow-container position-relative" style={{ background: "#fff", cursor: "pointer" }} onClick={() => { expandMenu() }}>
					<p className="p-0 m-0 text-center" >{expandMenuConquistas ? 'Recolher' : 'Expandir'}</p>
					<div className="arrow bounce"
						style={{
							rotate: expandMenuConquistas ? "180deg" : "0deg",
						}}></div>
				</div>
			</ProgressoNivel>
			<Etapas className="p-0">
				{/* <hr /> */}
				<div
					className="task-section"
					style={{
						opacity: expandMenuConquistas ? 1 : 0,
						height: expandMenuConquistas ? '580px' : 0,
						transition: 'opacity 1s, height 1s',
					}}
				>
					{ConquistasUsuario.conquistaTarefas ? ConquistasUsuario.conquistaTarefas.map(({ descricao, icone, titulo, mesesAlcancados }, index) => titulo !== 'Acesso Semanal' && (
						<div className="task-card-conteudo" key={index}>
							<hr />
							<div className="icon-task">
								<img src={icone ? icone : ''} alt="" />
								<p>{titulo ? titulo : ''}</p>
							</div>
							<div className="task-content">
								<div className="text-task">
									<p>{descricao ? descricao : ''}</p>
								</div>
								<div className="check-task">
									{mesesAlcancados === 1 ?
										<div>
											<img src={IconTaskChecked} alt="" />
											<img src={images.IconTaskAusente} alt="" />
											<img src={images.IconTaskAusente} alt="" />
										</div>
										: mesesAlcancados === 2 ?
											<div>
												<img src={IconTaskChecked} alt="" />
												<img src={IconTaskChecked} alt="" />
												<img src={images.IconTaskAusente} alt="" />
											</div>
											: mesesAlcancados === 3 ?
												<div>
													<img src={IconTaskChecked} alt="" />
													<img src={IconTaskChecked} alt="" />
													<img src={IconTaskChecked} alt="" />
												</div> :
												<div>
													<img src={images.IconTaskAusente} alt="" />
													<img src={images.IconTaskAusente} alt="" />
													<img src={images.IconTaskAusente} alt="" />
												</div>

									}
								</div>
							</div>
						</div>

					)
					) : ('')}
					<hr />
					<p className="text-aviso text-center ">* A atualização de vendas será feita em até 30 dias no mês seguinte.</p>

					{/* <Link
						to="/planilhas"
						className="btn btn-verde-outline w-100 d-flex align-items-center justify-content-center btn-height-3"
					>
						Veja os dados detalhados
					</Link> */}
				</div>
			</Etapas>
		</>
	);
}

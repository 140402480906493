import styled from 'styled-components'

export const NavBarPrincipal = styled.div`
background: #fff;
    .logo-nav-bar{
        width: 108px;
        height: 45px;
    }

    .modalMsgErro {
        @media(max-width: 990px) {
            position: relative;
            z-index: 9999;
        }
    }
    
`
export const MenuMobile = styled.div`
    .logo-nav-bar-mobile{
        width: 70px;
        height: 45px;
    }
    .container-items-menu{
        background: red;
    }
    @media (min-width: 992px) { 
        display: none;
    }
`;

export const MenuDesk = styled.div`
    position: relative;
    @media (max-width: 992px) { 
        display: none;
     }
     @media (max-width: 1400px) and (min-width:990px ){ 
        .gestor{
            .nav-link{
            font-size: 11px;
            margin-left: 10px;
            }
        }   
     }
     .gestor{
        .notificacao-icon{
            top: -5px;
            width: 21px;
            height: 21px;
            p{
                margin-top:1px;
                margin-right:1px;
            }
        }
     }
    .nav-link{
        position: relative;
    }
    .notificacao-icon{
        right: -10px;
        width: 24px;
        height: 24px;
        background-color: red;
        position: absolute;
        cursor: pointer;
        border-radius: 50%;
        top: -6px;
        z-index: 4;
        text-align: center;
        border: 2px solid rgb(255, 255, 255);
        color: white;
        box-shadow: rgba(0, 0, 0, 0.1) -3px 1px 6px 0px;
        p{
            margin-top: 3px;
            font-size: 12px;
        }
    }
`;

import { useState, useRef, useEffect } from "react";
import {
  Row,
  Form,
  Container,
  Col,
  Button,
  ListGroup,
  Card,
  Fade,
  Modal,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { NavBar } from "../../components/NavBar";
import {
  ModalSucesso,
  MeusDadosCampos,
  MenuChange,
  ButtoesNavMobile,
} from "./styles";
import { Footer } from "../../components/Footer/index";
import ImgPerfil from "../../images/img/img-perfil.svg";
import Checked from "../../images/img/icon-checked.svg";
import { Formik } from "formik";
import * as yup from "yup";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import api from "../../services/api";
import { rotasApi } from "../../services/rotasApi";
import axios from "axios";
import ExcluirContaIcon from "../../images/img/excluir-conta.svg";
import { getLoginReset } from "../../slices/loginSlice";

export function MeuPerfil({ user }) {
  //const [imgAvatar, setImgAvatar] = useState(ImgPerfil);
  const [imgAviso, setImgAviso] = useState(false);
  const [modalAviso, setModalAviso] = useState(false);
  const [msgModal, setModalMsg] = useState("");
  const [modalType, setModalType] = useState("success");
  const dadosUsuario = JSON.parse(sessionStorage.getItem("UserInfo"));
  const [enderecoAPI, setEnderecoAPI] = useState(true);
  const form1Ref = useRef(null);
  const formDadosRef = useRef(null);
  const [passwordShownConfirma, setPasswordShownConfirma] = useState(false);
  const [changeEyeConfirma, setChangeEyeConfirma] = useState(
    "bi bi-eye-slash pass-hide-cadastro"
  );
  const [modalSucesso, setModalSucesso] = useState(false);
  const [fotoPerfil, setFotoPerfil] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [passwordShowExcluir, setPasswordShowExcluir] = useState(false);

  function updateLocalStorage() {
    sessionStorage.setItem("UserInfo", JSON.stringify(user));
  }

  const fechaModal = () => {
    setModalSucesso(false);
  };

  let fotoString = user.foto.split("FotoPerfil/");
  let temFoto = fotoString[1];

  useEffect(() => {
    if (dadosUsuario.genero === "\u0000") {
      formDadosRef.current.setFieldValue("sexo", "Selecionar");
    }
    Number(temFoto.charAt(0))
      ? setFotoPerfil(user.foto)
      : setFotoPerfil(ImgPerfil);
  }, [fotoPerfil, user, temFoto, dadosUsuario]);

  const togglePasswordConfirma = () => {
    passwordShownConfirma
      ? setChangeEyeConfirma("bi bi-eye-slash pass-hide-cadastro")
      : setChangeEyeConfirma("bi bi-eye pass-hide-cadastro");
    setPasswordShownConfirma(!passwordShownConfirma);
  };

  const togglePasswordExcluir = () => {
    passwordShowExcluir
      ? setChangeEyeConfirma("bi bi-eye-slash pass-hide-cadastro")
      : setChangeEyeConfirma("bi bi-eye pass-hide-cadastro");
    setPasswordShowExcluir(!passwordShowExcluir);
  };
  const validationSenha = yup.object().shape({
    senha: yup
      .string()
      .min(8, "Senha deve ter pelo menos 8 caracteres")
      .matches(
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
        {
          message: "Senha inválida",
          excludeEmptyString: true,
        }
      )
      .required("Senha é obrigatório"),
  });
  const salvarDadosCadastrais = (values) => {
    const formData = new FormData();
    formData.append("apelido", values.apelido);
    formData.append("nomemae", values.nomeMae);
    formData.append("genero", values.sexo);
    formData.append("estadocivil", values.estadoCivil);
    formData.append("qtdfilho", values.filhos);
    formData.append("telefone", values.telefone);
    formData.append("timecoracao", values.time);

    api
      .put(
        rotasApi.atualizaDadosCadastraisUsuario + `${dadosUsuario.id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((result) => {
        // setModalType('success')
        setModalMsg(result.data.mensagem);
        setModalSucesso(true);
        setTimeout(() => {
          setModalSucesso(false);
        }, 5000);
        user.apelido = values.apelido;
        user.nomeMae = values.nomeMae;
        user.genero = values.sexo;
        user.estadoCivil = values.estadoCivil;
        user.qtdFilho = values.filhos;
        user.telefone = values.telefone;
        user.timeCoracao = values.time;
        updateLocalStorage();
        formDadosRef.current.setFieldValue("sexo", user.genero);
      })
      .catch((error) => {
        // console.log(error.response);
        setModalMsg(error.response.data.mensagem);
        setModalType("danger");
        setModalAviso(true);
        setTimeout(() => {
          setModalAviso(false);
        }, 5000);
      });
  };

  const salvarDadosCadastraisEndereco = (values) => {
    const formData = new FormData();
    formData.append("cep", values.cep);
    formData.append("estado", values.uf);
    formData.append("cidade", values.cidade);
    formData.append("bairro", values.bairro);
    formData.append("endereco", values.rua);
    formData.append("numero", values.numeroCasa);
    formData.append("complemento", values.complemento);
    formData.append("pontodereferencia", values.pontoReferencia);

    api
      .put(
        rotasApi.atualizaDadosCadastraisEndereco + `${dadosUsuario.id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((result) => {
        // setDuvidas(result.data.duvidas);
        setModalMsg(result.data.mensagem);
        setModalSucesso(true);
        // setModalType('success')
        setTimeout(() => {
          setModalSucesso(false);
        }, 5000);

        user.cep = values.cep;
        user.estado = values.uf;
        user.bairro = values.bairro;
        user.cidade = values.cidade;
        user.endereco = values.rua;
        user.numero = values.numeroCasa;
        user.complemento = values.complemento;
        user.pontoDeReferencia = values.pontoReferencia;
        updateLocalStorage();
      })
      .catch((error) => {
        // console.log(error.response);
        setModalMsg(error.response.data.mensagem);
        setModalType("danger");
        setModalAviso(true);
        setTimeout(() => {
          setModalAviso(false);
        }, 5000);
      });
  };

  const atualizaDadosCadastraisSenha = (values) => {
    const formData = new FormData();
    formData.append("cpf", dadosUsuario.cpf);
    formData.append("novasenha", values.senhaNova);
    formData.append("senhaatual", values.senhaAtual);

    api
      .post(rotasApi.redefinicaoSenhaComSenhaAnterior, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((result) => {
        // setDuvidas(result.data.duvidas);
        console.log(result);
        setModalMsg(result.data.mensagem);
        setModalSucesso(true);
        // setModalType('success')
        setTimeout(() => {
          setModalSucesso(false);
        }, 5000);
      })
      .catch((error) => {
        // console.log(error.response.data.mensagem);
        setModalMsg(error.response.data.mensagem);
        setModalType("danger");
        setModalAviso(true);
        setTimeout(() => {
          setModalAviso(false);
        }, 5000);
      });
  };

  const ImageAvatar = (e) => {
    let file = e.target.files[0];
    setFotoPerfil(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append("imagem", file);
    api
      .put(rotasApi.atualizaFotoPerfil + `${dadosUsuario.id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((result) => {
        let infoUsuario = dadosUsuario;
        infoUsuario.foto = result.data.url_imagem;
        user.foto = result.data.url_imagem;
        setFotoPerfil(result.data.url_imagem);
        sessionStorage.setItem("UserInfo", JSON.stringify(infoUsuario));
        setModalMsg(result.data.mensagem);
        setModalSucesso(true);
        setTimeout(() => {
          setModalSucesso(false);
        }, 5000);
      })
      .catch((error) => {
        setModalMsg(error.response.data.mensagem);
        setModalType("danger");
        setModalAviso(true);
        setTimeout(() => {
          setModalAviso(false);
        }, 5000);
      });
  };
  let [passo, setPasso] = useState(1);

  function mudarPasso(num) {
    setPasso((passo = num));
  }

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear() - 18;

  today = yyyy + "-" + mm + "-" + dd;

  const validationSchemaDadosCadastrais = yup.object().shape({
    sexo: yup
      .string()
      .required("Sexo é Obrigatório")
      .test("sexo", "Sexo é obrigatório", (val) => {
        if (val === "Selecionar") {
          return false;
        } else {
          return true;
        }
      }),
    //  filhos: yup
    //    .string()
    //   .min(1, "Filhos Obrigatório")
    //   .required("Filhos Obrigatório") ,
    // celular: yup
    //   .string()
    //   .min(10, "Numero inválido")
    //   .matches(
    //     /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/,
    //     "Numero inválido"
    //   )
    //   .required("Celular é obrigatório"),
    telefone: yup
      .string()
      .min(10, "Mínimo 10 digitos")
      .max(11, "Maximo 11 digitos")
      .required("Telefone é obrigatório"),
    //time: yup.string().min(3, "Time Obrigatório").required("Time Obrigatório"),
  });

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  let dispatch = useDispatch();
  const excluirContaFunction = () => {
    api
      .post(rotasApi.inativarUsuario, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((result) => {
        console.log('conta desativada')
        dispatch(getLoginReset());
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("UserInfo");
        window.location.href = '/'
      })
      .catch((error) => {
        console.log(error)
      });
  };
  const handleShow = () => setShow(true);
  const validationSchemaEndereco = yup.object().shape({
    cep: yup
      .string()
      .test("cep", "buscar cep", (val) => {

        if (val) {
          if (enderecoAPI && val.length === 8) {
            console.log('val includes >', val.includes('-'))
            if (!val.includes('-')) {
              form1Ref.current.setFieldValue('cep', val.slice(0, 5) + '-' + val.slice(5, 8));
            }

            console.log('cep > ', val)
            handleCEP(val)
            setEnderecoAPI(false)
          }
          if (val.length < 9) {
            setEnderecoAPI(true)
          }
        }
        return true
      })
      .required("CEP é obrigatório")
      .matches(/^[0-9]{5}-[0-9]{3}$/, "preencha no formato xxxxx-xxx"),
    rua: yup
      .string()
      .min(3, "Endereço Obrigatório")
      .required("Endereço Obrigatório"),
    bairro: yup
      .string()
      .min(4, "Bairro Obrigatório")
      .required("Bairro Obrigatório"),
    cidade: yup
      .string()
      .min(4, "Cidade Obrigatório")
      .required("Cidade Obrigatório"),
    uf: yup
      .string()
      .min(2, "Estado Obrigatório")
      .required("Estado Obrigatório"),
  });
  const validationSchemaSenhas = yup.object().shape({
    senhaAtual: yup
      .string()
      .min(8, "Senha Atual Obrigatório")
      .required("Senha Atual Obrigatório"),
    senhaNova: yup
      .string()
      .min(8, "Senha deve ter pelo menos 8 caracteres")
      .matches(
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
        {
          message: "Senha inválida",
          excludeEmptyString: true,
        }
      )
      .required("Senha é obrigatório"),

    senhaNovaConfirma: yup.string().when("senha", (value, schema) => {
      return schema
        .oneOf([yup.ref("senhaNova")], "As senhas devem ser iguais")
        .required("Confirmar Senha é obrigatório");
    }),
  });
  const handleCEP = (cep) => {
    axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((result) => {
        form1Ref.current.setFieldValue("rua", result.data.logradouro);
        form1Ref.current.setFieldValue("bairro", result.data.bairro);
        form1Ref.current.setFieldValue("cidade", result.data.localidade);
        form1Ref.current.setFieldValue("uf", result.data.uf);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <div className="meu-perfil-blackfriday">
        <Modal
          show={modalSucesso}
          onHide={() => setModalSucesso(false)}
          dialogClassName="modal-90w"
          className="modal-sucesso"
          centered
        >
          <Modal.Body className="text-center">
            <ModalSucesso>
              <div className="modal-sucesso">
                <img src={Checked} alt="" className="img-sucesso" />
                <h2 className="h2-sucesso">{msgModal}</h2>
              </div>
              <Button
                className="btn-laranja btn-enviar col-8"
                onClick={fechaModal}
              >
                Fechar
              </Button>
            </ModalSucesso>
          </Modal.Body>
        </Modal>

        <Row>
          <Col className="d-flex justify-content-center">
            <Fade in={modalAviso}>
              <Card
                className="text-center p-3 m-2 z-index-top position-absolute"
                bg={modalType}
                text="white"
              >
                <Card.Body>
                  <Card.Title as="h4">{msgModal}</Card.Title>
                  <Card.Text></Card.Text>
                </Card.Body>
              </Card>
            </Fade>
          </Col>
        </Row>

        <NavBar user={user} />
        <MeusDadosCampos>
          <Container className="col-8 col-lg-12 d-flex flex-wrap p-0 justify-content-center pb-5">
            <Row className="mt-3 d-flex bd-highlight">
              <Breadcrumb>
                <Link className="breadcrumb-item" to="/home">
                  Home
                </Link>
                <Breadcrumb.Item active>Meu Perfil</Breadcrumb.Item>
                {passo === 1 ? (
                  <Breadcrumb.Item active>Dados Cadastrais</Breadcrumb.Item>
                ) : passo === 2 ? (
                  <Breadcrumb.Item active>Endereço</Breadcrumb.Item>
                ) : passo === 3 ? (
                  <Breadcrumb.Item active>Privacidade</Breadcrumb.Item>
                ) : (
                  ""
                )}
              </Breadcrumb>
              <hr className="mb-5" />
              <Row className="p-0 m-0">
                <Col className="p-0 m-0">
                  <h2 className="text-center mt-3 mb-4 titulo-meu-perfil">
                    Meu Perfil
                  </h2>
                  <ButtoesNavMobile className="botoes-nav-mobile">
                    <div
                      onClick={() => mudarPasso(1)}
                      className={passo === 1 ? "active" : ""}
                    >
                      Dados
                      <br />
                      cadastrais
                    </div>
                    <div
                      onClick={() => mudarPasso(2)}
                      className={passo === 2 ? "active" : ""}
                    >
                      Endereço
                    </div>
                    <div
                      onClick={() => mudarPasso(3)}
                      className={passo === 3 ? "active" : ""}
                    >
                      Privacidade
                      <br /> e segurança
                    </div>
                  </ButtoesNavMobile>
                </Col>
              </Row>
              <Col lg={3} className="me-auto p-2 bd-highligh">
                <Row>
                  <MenuChange>
                    <ListGroup as="ul">
                      <ListGroup.Item as="li" className="pt-4">
                        <h3>Perfil</h3>
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        onClick={() => mudarPasso(1)}
                        className={passo === 1 ? "active" : ""}
                      >
                        Dados cadastrais
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        onClick={() => mudarPasso(2)}
                        className={passo === 2 ? "active" : ""}
                      >
                        Endereço
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        onClick={() => mudarPasso(3)}
                        className={passo === 3 ? "active" : ""}
                      >
                        Privacidade e segurança
                      </ListGroup.Item>
                    </ListGroup>
                  </MenuChange>
                </Row>
              </Col>

              <Col
                sm={12}
                lg={8}
                className="d-flex justify-content-center align-inputs"
              >
                {passo === 1 ? (
                  <div className="titulo-page-mobile text-center">
                    <h2>Dados Cadastrais</h2>
                    <span>Preencha todos os campos com (*)</span>
                  </div>
                ) : passo === 2 ? (
                  <div className="titulo-page-mobile text-center">
                    <h2>Endereço</h2>
                    <span>Preencha todos os campos com (*)</span>
                  </div>
                ) : passo === 3 ? (
                  <div className="titulo-page-mobile text-center">
                    <h2>Quer alterar sua senha ?</h2>
                    <span>Preencha todos os campos</span>
                  </div>
                ) : (
                  ""
                )}

                <Col
                  sm={12}
                  lg={3}
                  className="position-relative container-img-prev"
                >
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <img
                        src={fotoPerfil}
                        className="img-prev img-fluid"
                        alt=""
                      />
                    </Col>
                  </Row>

                  <Form.Group controlId="formFile">
                    <Form.Control
                      className="input-img"
                      accept=".png, .jpg, .jpeg"
                      type="file"
                      onChange={(e) => {
                        if (e.currentTarget.files[0].size <= 200000) {
                          ImageAvatar(e);
                          // setFieldValue(
                          //   "avatarImg",
                          //   e.currentTarget.files[0]
                          // );
                          setImgAviso(false);
                        } else {
                          setImgAviso(true);
                        }
                      }}
                    // isInvalid={!!errors.avatarImg}
                    />

                    {imgAviso && (
                      <p className="img-invalid text-center small text-danger w-100">
                        A imagem é muito grande
                      </p>
                    )}
                    <div className="formatos-imgs">
                      <p className="text-center small m-0">
                        Clique para adicionar uma imagem ao seu perfil
                      </p>
                      <p className="text-center small">
                        formatos aceitos: jpg/png até 2 MB
                      </p>
                    </div>
                  </Form.Group>
                </Col>

                <Formik
                  innerRef={formDadosRef}
                  initialValues={{
                    id: dadosUsuario.id,
                    avatarImg: {},
                    rede: dadosUsuario.lojas[0].rede.nome || "",
                    loja: dadosUsuario.lojas[0].nome || "",
                    cargo: dadosUsuario.cargo.descricao || "",
                    nome: dadosUsuario.nome || "",
                    apelido: dadosUsuario.apelido || "",
                    nomeMae: dadosUsuario.nomeMae || "",
                    dataNascimento:
                      dadosUsuario.dataNascimento.slice(0, 4) +
                      "-" +
                      dadosUsuario.dataNascimento.slice(5, 7) +
                      "-" +
                      dadosUsuario.dataNascimento.slice(8, 10),
                    sexo: dadosUsuario.genero || "",
                    estadoCivil: dadosUsuario.estadoCivil || "",
                    filhos: dadosUsuario.qtdFilho,
                    email: dadosUsuario.email || "",
                    emailConfirma: dadosUsuario.email || "",
                    celular: dadosUsuario.celular || "",
                    telefone: dadosUsuario.telefone || "",
                    time: dadosUsuario.timeCoracao || "",
                  }}
                  onSubmit={(values) => {
                    salvarDadosCadastrais(values);
                  }}
                  validationSchema={validationSchemaDadosCadastrais}
                >
                  {({
                    values,
                    handleChange,
                    errors,
                    isValid,
                    handleSubmit,
                    setFieldValue,
                    dirty,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit} onKeyDown={onKeyDown}>
                      {passo === 1 && (
                        <Col lg={7}>
                          <div className="container-campos ">
                            <Row className="justify-content-center">
                              <div className="titulo-page">
                                <h2>Dados Cadastrais</h2>
                                <span>Preencha todos os campos com (*)</span>
                              </div>
                              <Row className="p-0">
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="label-input">
                                      Rede
                                    </Form.Label>

                                    <Form.Control
                                      placeholder=""
                                      type="text"
                                      name="rede"
                                      value={values.rede}
                                      onChange={handleChange}
                                      disabled
                                    />
                                  </Form.Group>

                                  <Form.Group className="mb-3">
                                    <Form.Label className="label-input">
                                      Loja
                                    </Form.Label>

                                    <Form.Control
                                      placeholder=""
                                      type="text"
                                      name="loja"
                                      value={values.loja}
                                      onChange={handleChange}
                                      disabled
                                    />
                                  </Form.Group>

                                  <Form.Group className="mb-3">
                                    <Form.Label className="label-input">
                                      Cargo
                                    </Form.Label>

                                    <Form.Control
                                      placeholder=""
                                      type="text"
                                      name="cargo"
                                      value={values.cargo}
                                      onChange={handleChange}
                                      disabled
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>

                              <Row className="d-flex justify-content-center mt-5">
                                <Col className="p-0">
                                  <Form.Group className="mb-3">
                                    <Form.Label className="label-input">
                                      Nome
                                    </Form.Label>

                                    <Form.Control
                                      placeholder=""
                                      type="text"
                                      name="nome"
                                      value={values.nome}
                                      onChange={handleChange}
                                      disabled
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="label-input">
                                      Como gostaria de ser chamado?
                                    </Form.Label>

                                    <Form.Control
                                      placeholder=""
                                      type="text"
                                      name="apelido"
                                      value={values.apelido}
                                      onChange={handleChange}
                                    />

                                  </Form.Group>
                                  <Form.Group className="mb-3 d-none">
                                    <Form.Label className="label-input">
                                      Nome de mãe
                                    </Form.Label>

                                    <Form.Control
                                      placeholder=""
                                      type="text"
                                      name="nomeMae"
                                      value={values.nomeMae}
                                      onChange={handleChange}
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="label-input">
                                      Data de nascimento
                                    </Form.Label>

                                    <Form.Control
                                      max={today}
                                      step="1"
                                      type="date"
                                      name="dataNascimento"
                                      value={values.dataNascimento}
                                      onChange={handleChange}
                                      disabled
                                    />
                                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                  </Form.Group>

                                  <Form.Group className="mb-3">
                                    <Form.Label className="label-input">
                                      Sexo*
                                    </Form.Label>
                                    <Form.Select
                                      type="text"
                                      name="sexo"
                                      value={values.sexo}
                                      onChange={handleChange}
                                      isInvalid={!!errors.sexo}
                                    >
                                      <option>Selecionar</option>
                                      <option value="M">Masculino</option>
                                      <option value="F">Feminino</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.sexo}
                                    </Form.Control.Feedback>
                                  </Form.Group>

                                  <Form.Group className="mb-3">
                                    <Form.Label className="label-input">
                                      Estado civil
                                    </Form.Label>

                                    <Form.Select
                                      placeholder=""
                                      type="text"
                                      name="estadoCivil"
                                      value={values.estadoCivil}
                                      onChange={handleChange}
                                    >
                                      <option>Selecionar</option>
                                      <option value="S">Solteiro</option>
                                      <option value="C">Casado</option>
                                      <option value="D">Divorciado</option>
                                      <option value="V">Viuvo</option>
                                    </Form.Select>
                                  </Form.Group>
                                  <Form.Group className="mb-3 d-none">
                                    <Form.Label className="label-input">
                                      Filhos
                                    </Form.Label>
                                    <Form.Select
                                      placeholder=""
                                      type="text"
                                      name="filhos"
                                      value={values.filhos}
                                      onChange={handleChange}
                                    >
                                      <option>0</option>
                                      <option>1</option>
                                      <option>2</option>
                                      <option>3</option>
                                      <option>4</option>
                                      <option value="5">5 ou Mais</option>
                                    </Form.Select>

                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="d-flex justify-content-center mt-5">
                                <Col className="p-0">
                                  <Form.Group className="mb-3">
                                    <Form.Label className="label-input">
                                      E-mail*
                                    </Form.Label>

                                    <Form.Control
                                      placeholder=""
                                      type="text"
                                      name="email"
                                      value={values.email}
                                      onChange={handleChange}
                                      disabled
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="label-input">
                                      Confirmar e-mail*
                                    </Form.Label>

                                    <Form.Control
                                      placeholder=""
                                      type="text"
                                      name="emailConfirma"
                                      value={values.emailConfirma}
                                      onChange={handleChange}
                                      disabled
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="label-input">
                                      Celular (com DDD)*
                                    </Form.Label>

                                    <Form.Control
                                      // as={phoneMask}
                                      placeholder=""
                                      type="text"
                                      name="celular"
                                      value={values.celular}
                                      onChange={handleChange}
                                      isInvalid={!!errors.celular}
                                      disabled
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.celular}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="label-input">
                                      Telefone(com DDD)*
                                    </Form.Label>

                                    <Form.Control
                                      // as={phoneMask}
                                      placeholder=""
                                      type="text"
                                      name="telefone"
                                      value={values.telefone}
                                      onChange={handleChange}
                                      isInvalid={!!errors.telefone}
                                    // disabled
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.telefone}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group className="mb-3 d-none">
                                    <Form.Label className="label-input">
                                      Time do coração
                                    </Form.Label>

                                    <Form.Control
                                      placeholder=""
                                      type="text"
                                      name="time"
                                      value={values.time}
                                      onChange={handleChange}
                                    />
                                  </Form.Group>
                                  <Button
                                    // onClick={() => mudarPasso(2)}
                                    // onClick={(e)=>testeAndre(e.target.value)}
                                    disabled={!isValid || !dirty}
                                    type="submit"
                                    className="w-100 mt-4 btn-laranja"
                                    variant="secondary"
                                  >
                                    Salvar
                                  </Button>
                                </Col>
                              </Row>
                            </Row>
                          </div>
                        </Col>
                      )}
                    </Form>
                  )}
                </Formik>

                {/* ) */}
                {/* : passo === 2 ? ( */}
                <Formik
                  innerRef={form1Ref}
                  initialValues={{
                    id: dadosUsuario.id,
                    cep: dadosUsuario.cep || "",
                    rua: dadosUsuario.endereco || "",
                    numeroCasa: dadosUsuario.numero || "",
                    complemento: dadosUsuario.complemento || "",
                    bairro: dadosUsuario.bairro || "",
                    cidade: dadosUsuario.cidade || "",
                    uf: dadosUsuario.estado || "",
                    pontoReferencia: dadosUsuario.pontoDeReferencia || "",
                  }}
                  onSubmit={(values) => {
                    // mudarPasso();
                    salvarDadosCadastraisEndereco(values);
                  }}
                  validationSchema={validationSchemaEndereco}
                >
                  {({
                    values,
                    handleChange,
                    errors,
                    isValid,
                    handleSubmit,
                    touched,
                    setFieldValue,
                    dirty,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit} onKeyDown={onKeyDown}>
                      {passo === 2 && (
                        <Col lg={7}>
                          <div className="container-campos align-self-center ">
                            <Row>
                              <div className="titulo-page">
                                <h2>Endereço</h2>
                                <span>Preencha todos os campos com (*)</span>
                              </div>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  CEP*
                                </Form.Label>
                                <div className="small position-relative">
                                  <a
                                    className="busca-cep"
                                    rel="noopener noreferrer"
                                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                                    target="_blank"
                                  >
                                    Não sei meu CEP
                                  </a>
                                </div>
                                <Form.Control
                                  maxLength="9"
                                  placeholder=""
                                  type="text"
                                  name="cep"
                                  value={values.cep}
                                  onChange={handleChange}
                                  isInvalid={!!errors.cep}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cep}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Rua / Avenida / Travessa / Praça*
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="rua"
                                  value={values.rua}
                                  onChange={handleChange}
                                  isInvalid={!!errors.rua}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.rua}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Número*
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="number"
                                  name="numeroCasa"
                                  value={values.numeroCasa}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Complemento
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="complemento"
                                  value={values.complemento}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Bairro*
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="bairro"
                                  value={values.bairro}
                                  onChange={handleChange}
                                  isInvalid={!!errors.bairro}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.bairro}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Cidade*
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="cidade"
                                  value={values.cidade}
                                  onChange={handleChange}
                                  isInvalid={!!errors.cidade}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cidade}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  UF*
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="uf"
                                  value={values.uf}
                                  onChange={handleChange}
                                  isInvalid={!!errors.uf}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.uf}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label className="label-input">
                                  Ponto de referência
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type="text"
                                  name="pontoReferencia"
                                  value={values.pontoReferencia}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </Row>
                            <Button
                              // onClick={() => mudarPasso(3)}
                              disabled={!isValid || !dirty}
                              type="submit"
                              className="w-100 mt-4 btn-laranja"
                              variant="secondary"
                            >
                              Salvar
                            </Button>
                          </div>
                        </Col>
                      )}
                    </Form>
                  )}
                </Formik>

                <Formik
                  initialValues={{
                    cpf: dadosUsuario.cpf,
                    senhaAtual: "",
                    senhaNova: "",
                    senhaNovaConfirma: "",
                  }}
                  onSubmit={(values) => {
                    // mudarPasso();
                    atualizaDadosCadastraisSenha(values);
                  }}
                  validationSchema={validationSchemaSenhas}
                >
                  {({
                    values,
                    handleChange,
                    errors,
                    isValid,
                    handleSubmit,
                    setFieldValue,
                    dirty,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit} onKeyDown={onKeyDown}>
                      {passo === 3 && (
                        <Col lg={7} style={{ minHeight: "83vh" }}>
                          <div className="container-campos align-self-center ">
                            <Row>
                              <div className="titulo-page">
                                <h2>Quer alterar sua senha ?</h2>
                                <span>Preencha todos os campos</span>
                              </div>
                              <Form.Group className="mb-3 position-relative">
                                <Form.Label className="label-input ">
                                  Senha Atual
                                </Form.Label>
                                <Form.Control
                                  placeholder=""
                                  type={
                                    passwordShownConfirma ? "text" : "password"
                                  }
                                  name="senhaAtual"
                                  value={values.senhaAtual}
                                  onChange={handleChange}
                                  isInvalid={!!errors.senhaAtual}
                                />
                                <i
                                  className={changeEyeConfirma}
                                  onClick={togglePasswordConfirma}
                                ></i>
                                <Form.Control.Feedback type="invalid">
                                  {errors.senhaAtual}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3 position-relative">
                                <Form.Label className="label-input">
                                  Senha Nova *
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type={
                                    passwordShownConfirma ? "text" : "password"
                                  }
                                  name="senhaNova"
                                  value={values.senhaNova}
                                  onChange={handleChange}
                                  isInvalid={!!errors.senhaNova}
                                />
                                <i
                                  className={changeEyeConfirma}
                                  onClick={togglePasswordConfirma}
                                ></i>
                                <Form.Control.Feedback type="invalid">
                                  {errors.senhaNova}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group className="mb-3 position-relative">
                                <Form.Label className="label-input">
                                  Confirmar senha
                                </Form.Label>

                                <Form.Control
                                  placeholder=""
                                  type={
                                    passwordShownConfirma ? "text" : "password"
                                  }
                                  name="senhaNovaConfirma"
                                  value={values.senhaNovaConfirma}
                                  onChange={handleChange}
                                  isInvalid={!!errors.senhaNovaConfirma}
                                />
                                <i
                                  className={changeEyeConfirma}
                                  onClick={togglePasswordConfirma}
                                ></i>
                                <Form.Control.Feedback type="invalid">
                                  {errors.senhaNovaConfirma}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <p className="font-weight-bold info-senha">
                                A senha precisa conter no mínimo 8 carácteres,
                                uma letra maiúscula, uma letra minúscula, um
                                número e um caractere especial (ex.: @, !, ?,
                                %).
                              </p>
                            </Row>
                            <Button
                              type="submit"
                              className="w-100 mt-4 btn-laranja"
                              variant="secondary"
                              disabled={!isValid || !dirty}
                            >
                              Salvar
                            </Button>
                            <Button
                              className="w-100 mt-4 btn-laranja-outline"
                              variant="secondary"
                              onClick={handleShow}
                            >Excluir minha conta
                            </Button>
                          </div>
                        </Col>
                      )}
                    </Form>
                  )}
                </Formik>

                {/* ) : (
                      ""
                    )} */}




                <Modal show={show} onHide={handleClose}>

                  <Formik
                    initialValues={{
                      cpf: dadosUsuario.cpf,
                      senha: "",
                    }}
                    onSubmit={(values) => {
                      // mudarPasso();
                      excluirContaFunction(values);
                    }}
                    validationSchema={validationSenha}
                  >
                    {({
                      values,
                      handleChange,
                      errors,
                      isValid,
                      handleSubmit,
                      setFieldValue,
                      dirty,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit} onKeyDown={onKeyDown}>

                        <Modal.Header className="d-flex justify-content-center border-0 flex-column text-center">
                          <img src={ExcluirContaIcon} alt="" className="img-sucesso" />
                          <Modal.Title className="fw-bold mt-3">
                            Você tem certeza que quer sair do programa +top?
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p className="text-center">Além de não poder participar das campanhas e não ganhar pontos com suas vendas de produtos Brastemp e Consul, essa exclusão será definitiva e, uma vez excluído, seu perfil perderá o histórico e acesso aos pontos.</p>

                          <Form.Group className="mb-3">
                            <Form.Label className="label-input ">
                              Senha
                            </Form.Label>
                            <div className="position-relative">
                              <i
                                style={{ fontSize: '20px', margin: '1.4rem 1.5rem' }}
                                className={changeEyeConfirma + " position-absolute top-0 end-0 translate-middle"}
                                onClick={togglePasswordExcluir}
                              ></i>
                            </div>
                            <Form.Control
                              placeholder=""
                              type={
                                passwordShowExcluir ? "text" : "password"
                              }
                              name="senha"
                              value={values.senha}
                              onChange={handleChange}
                              isInvalid={!!errors.senha}
                            />

                            <Form.Control.Feedback type="invalid">
                              {errors.senha}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            className="w-100 mt-4 btn-laranja"
                            onClick={handleClose}
                          >
                            Não, quero continuar
                          </Button>
                          <Button
                            type="submit"
                            variant="light"
                            className="w-100 mt-4 btn-laranja-outline"
                            disabled={!isValid || !dirty}
                          >
                            Sim, quero sair
                          </Button>
                        </Modal.Footer>

                      </Form>
                    )}
                  </Formik>
                </Modal>
              </Col>
            </Row>
          </Container>
        </MeusDadosCampos>
        <Footer />
      </div>
    </>
  );
}

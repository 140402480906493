import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Card,
  Fade,
  Form,
  Modal,
} from "react-bootstrap";
// import { Link } from "react-router-dom";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import {
  Home,
  ContainerLeft,
  ContainerRight,
  Slider2,
  ProdutosPremium,
  SuperTops,
  ProdutosParticipantes,
  Notificacao,
  TopNews
} from "./styles";
import parse from "html-react-parser";
import ImgPerfil from "../../images/img/img-perfil.svg";
import { ConquistasUsuarioHome } from "../../components/ConsquistasUsuarioHome"
import Carousel from "react-bootstrap/Carousel";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate, Link } from "react-router-dom";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";
import { ModalAniversario } from "../../components/ModalAniversario";
import { ModalPesquisa } from "../../components/ModalPesquisa"
import { ModalPesquisaSac } from "../../components/ModalPesquisaSac"
// import { NeveEfeito } from "../../components/NeveEfeito";
import { ModalComunicado } from "../../components/ModalComunicado"



export function HomePage({ user }) {

  let navigate = useNavigate();
  const redirect = localStorage.getItem("redirect");

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const hj = new Date();
  let mesAtual = hj.toLocaleString("pt-BR", { month: "long" });
  mesAtual =
    mesAtual.charAt(0).toUpperCase() + mesAtual.slice(1, mesAtual.length);
  const anoAtual = hj.getFullYear();

  const [fotoPerfil, setFotoPerfil] = useState();
  const [saldoShow, setSaldoShow] = useState(false);
  const [saldoValor, setSaldoValor] = useState();
  const [modalErro, setModalErro] = useState(false);
  const [modalMensagem, setModalMensagem] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [modalErroCatalogo, setModalErroCatalogo] = useState(false);
  const [controls, setControls] = useState(false);
  const [produtosSuperTops, setProdutosSuperTops] = useState([]);
  const [produtosPremium, setProdutosPremium] = useState([]);
  const [categoriasParticipantes, setCategoriasParticipantes] = useState([]);
  const [aceiteTermosDeUso, setAceiteTermosDeUso] = useState();
  const [politicasDePrivacidade, setPoliticasDePrivacidade] = useState();
  const [marketing, setMarketing] = useState(false);
  const [modalShowRegulamento, setModalShowRegulamento] = useState(null);
  const [modalShowAceiteVendas, setModalShowAceiteVendas] = useState(null);
  const [modalShowAniversario, setmodalShowAniversario] = useState();
  const [modalShowPesquisa, setModalShowPesquisa] = useState();
  const [modalShowPesquisaSac, setModalShowPesquisaSac] = useState();
  const [mensagemPesquisaSac, setMensagemPesquisaSac] = useState()
  const [bannerSlide, setBannerSlide] = useState("");
  const [menuNotificacao, setMenuNotificacao] = useState("conteudo-notificacao oculto");
  const [menuNotificacaoActive, setMenuNotificacaoActive] = useState(false);
  const [notificacoesUsuario, setNotificacoesUsuario] = useState([]);
  const [dadosBannerComunicado, setDadosBannerComunicado] = useState([])
  const [listaPost, setListaPost] = useState(null);


  const listaProdutosSuperTops = () => {
    let data = new Date();
    let mes = data.getMonth() + 1;
    let ano = data.getFullYear();
    api
      .get(`${rotasApi.listaProdutosSuperTop}/${mes}/${ano}`)
      .then((result) => {
        setProdutosSuperTops(result.data.produtossupertop
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // listarMPV()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const listarMPV = () => {
  //   // localStorage.remove('')
  //   sessionStorage.removeItem("hasmpv");
  //   api
  //     .get(rotasApi.ListarMpvs)
  //     .then((result) => {
  //       localStorage.setItem('hasmpv', result.data.sucesso)
  //     }).catch((error) => {
  //       console.log('error obter list mpv')
  //       localStorage.setItem('hasmpv', error.response.data.sucesso)
  //     })
  // };

  const listaProdutosPremium = () => {
    let data = new Date();
    let mes = data.getMonth() + 1;
    let ano = data.getFullYear();
    api
      .get(`${rotasApi.listaProdutosPremium}/${mes}/${ano}`)
      .then((result) => {
        setProdutosPremium(result.data.produtossupertop);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const buscarBannerComunicado = () => {
    if (sessionStorage.getItem("acessoEspelho") !== 'true') {
      api
        .get(`${rotasApi.BuscarComunicado}`)
        .then((result) => {
          setDadosBannerComunicado(result.data.banners)
        })
        .catch((error) => {
          console.log(error);
        });
    } else { }
    console.log('Requisição Banner Comunicado bloqueada devido ao acesso espelho.')
  };


  const listaCategoriasParticipantes = () => {
    api
      .get(`${rotasApi.listaCategoriasParticipantes}`)
      .then((result) => {
        setCategoriasParticipantes(result.data.produtos);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const primeiraLetraMaiuscula = (nome) => {
    return nome[0].toUpperCase() + nome.substr(1).toLowerCase();
  };
  const ListarBannerHome = () => {

    api
      .get(`${rotasApi.ListarBanner}`)
      .then((result) => {
        setBannerSlide(result.data.banners)
      })
      .catch((error) => {
        console.log(error);
      });

  }
  const VerificarAniversario = () => {
    if (sessionStorage.getItem("acessoEspelho") !== 'true') {
      api
        .get(`${rotasApi.PontuacaoAniversario}`)
        .then((result) => {
          if (result.data.sucesso) {
            setmodalShowAniversario(true)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('Requisição de aniversariantes bloqueada devido ao acesso espelho!')
    }
  }
  const VerificarRespostaPesquisa = () => {
    if (sessionStorage.getItem("acessoEspelho") !== 'true') {
      api
        .get(`${rotasApi.ConsultarPesquisa}`)
        .then((result) => {
          if (result.data.mostrarModal) {
            setModalShowPesquisa(true)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('Requisiçao de pesquisa bloqueada devido ao acesso espelho!')
    }
  }

  const VerificarRespostaPesquisaSac = () => {
    if (sessionStorage.getItem("acessoEspelho") !== 'true') {
      api
        .get(`${rotasApi.consultarPesquisaSac}`)
        .then((result) => {
          if (result.data.pesquisaDto !== null) {
            setModalShowPesquisaSac(true)
            setMensagemPesquisaSac({
              mensagem: result.data.pesquisaDto.mensagem,
              idPesquisa: result.data.pesquisaDto.id
            })
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('Requisição de verificar resposta resposta SAC bloquada devido o acesso espelho.')
    }

  }
  useEffect(() => {
    user.aceitePoliticasDePrivacidade ? setModalShowRegulamento(false) : setModalShowRegulamento(true)
    if (modalShowAceiteVendas === false && modalShowRegulamento === false) {
      if (user.aceitePoliticasDePrivacidade) {
        VerificarAniversario()
      }
    } else { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalShowAceiteVendas, modalShowRegulamento])

  useEffect(() => {
    VerificarRespostaPesquisa()
    VerificarRespostaPesquisaSac()
    listaProdutosSuperTops();
    listaProdutosPremium();
    listaCategoriasParticipantes();
    ListarBannerHome();
    // VerificarRaspadinha();
    buscarBannerComunicado();
    setControls(false);
    let fotoString = user.foto.split("FotoPerfil/");
    let temFoto = fotoString[1];
    Number(temFoto.charAt(0))
      ? setFotoPerfil(user.foto)
      : setFotoPerfil(ImgPerfil);
    api
      .get(rotasApi.saldo)
      .then((result) => {
        if (result.data.sucesso) {
          setSaldoValor(result.data.saldo);
        } else {
          setModalErro(true);
          setModalMensagem(result.data);
          setTimeout(() => {
            setModalErro(false);
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        setModalErro(true);
        setModalMensagem(error.response.data.mensagem);
        setTimeout(() => {
          setModalErro(false);
        }, 5000);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, user]);

  const abreAbaPremios = (e) => {
    // if (sessionStorage.getItem("acessoEspelho") === 'true') {
    //   setModalErro(true);
    //   setModalMensagem('Catálogo de premios não pode ser acessado do acesso espelho.');
    //   setTimeout(() => {
    //     setModalErro(false);
    //   }, 3000);
    // } else {
    //   api
    //     .get(rotasApi.linkCatalogoPremios)
    //     .then((result) => {
    //       const { mensagem, url } = result.data;
    //       if (url.length > 0) {
    //         window.location.assign(`${url}`, "_blank");
    //         return;
    //       }
    //       setModalErroCatalogo(true);
    //       setModalMsg(mensagem);
    //       setTimeout(() => {
    //         setModalErroCatalogo(false);
    //       }, 3000);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
      setModalErro(true);
      setModalMensagem('O Catálogo de Prêmios está desabilitado momentaneamente.');
      setTimeout(() => {
        setModalErro(false);
      }, 3000);
    // }
  };

  const extratoDesabilitado = () => {
    setModalErro(true);
    setModalMensagem('O Extrato está desabilitado momentaneamente');
    setTimeout(() => {
      setModalErro(false);
    }, 3000);
  }

  // const abrirLinkYoutube = (e) => {
  //   window.open("https://drive.google.com/file/d/1WkoMbK1AvPOOcrCULbgsPKDy1M7rMaxM/view?usp=sharing", "_blank");
  // };

  const [changeEye, setChangeEye] = useState("bi bi-eye-slash icon-large");

  const toggleSaldo = () => {
    setSaldoShow(!saldoShow);
    saldoShow
      ? setChangeEye("bi bi-eye-slash icon-large")
      : setChangeEye("bi bi-eye icon-large");
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 5,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const settings2 = {
    infinite: true,
    rows: 2,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 2,
        },
      },
    ]
    // responsive: [
    //   {
    //     breakpoint: 992,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 3,
    //       infinite: true,
    //       dots: true,
    //     },
    //   },
    // ],
  };
  const ListarPostagens = () => {
    api
      .get(
        rotasApi.ListarPostagens,
      )
      .then((result) => {
        setListaPost(result.data.publicacoes.slice(0, 3))
      })
      .catch((error) => {
        console.log('error List post=->', error)
      });
  };


  useEffect(() => {
    user.aceitePontuacaoVendas ? setModalShowAceiteVendas(false) : setModalShowAceiteVendas(true);
    if (!user.aceiteRegulamento || !user.aceitePoliticasDePrivacidade) {
      setModalShowRegulamento(true);
      user.aceitePoliticasDePrivacidade ? setPoliticasDePrivacidade(true) : setPoliticasDePrivacidade(false);
      user.aceiteRegulamento ? setAceiteTermosDeUso(true) : setAceiteTermosDeUso(false);
      user.aceiteMarketing ? setMarketing(true) : setMarketing(false);
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ExibirNotificacao(true)
    ListarPostagens()
    user.aceitePontuacaoVendas ? setModalShowAceiteVendas(false) : setModalShowAceiteVendas(true);
    if (!user.aceiteRegulamento || !user.aceitePoliticasDePrivacidade) {
      setModalShowRegulamento(true);
      user.aceitePoliticasDePrivacidade ? setPoliticasDePrivacidade(true) : setPoliticasDePrivacidade(false);
      user.aceiteRegulamento ? setAceiteTermosDeUso(true) : setAceiteTermosDeUso(false);
      user.aceiteMarketing ? setMarketing(true) : setMarketing(false);
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PontuacaoVendasAceitar = () => {
    api
      .post(`${rotasApi.AceitePontuacaoVendas}`)
      .then((result) => {
        setModalShowAceiteVendas(false);
        user.aceitePontuacaoVendas = true;
        sessionStorage.setItem("UserInfo", JSON.stringify(user));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ExibirNotificacao = (NotificacaoState) => {

    if (NotificacaoState !== true) {
      menuNotificacaoActive ? setMenuNotificacao("conteudo-notificacao oculto") : setMenuNotificacao("conteudo-notificacao ativo")
      setMenuNotificacaoActive(!menuNotificacaoActive)
    } else { }

    api
      .get(`${rotasApi.BuscarNotificacaoUsuario}`)
      .then((result) => {

        setNotificacoesUsuario(result.data.notificacoes)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const NotificacaoLida = (e, index) => {

    api
      .post(`${rotasApi.MarcarNotificacaoLida}?notificacaoId=${e}`)
      .then((result) => {
        ExibirNotificacao(true)
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const AceiteRegulamentoMarketingPoliticas = () => {
    const formData = new FormData();
    formData.append("AceitePoliticasDePrivacidade", aceiteTermosDeUso);
    formData.append("AceiteRegulamento", politicasDePrivacidade);
    formData.append("AceiteMarketing", marketing);

    api
      .put(
        `${rotasApi.AceiteRegulamentoMarketingPoliticas}/${user.id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((result) => {
        user.aceiteRegulamento = true;
        user.aceitePoliticasDePrivacidade = true;
        user.aceiteMarketing = marketing;
        sessionStorage.setItem("UserInfo", JSON.stringify(user));
        setModalShowRegulamento(false);
      })
      .catch((error) => {
        console.error("Error during service worker registration:", error);
      });
  };

  const [data1, setData1] = useState("");

  const childToParent = (childdata) => {
    setData1(childdata);
  };

  const handleClose = () => setModalShowAceiteVendas(false);
  var today = new Date();
  var mm = String(today.getMonth() + 1);
  var yyyy = today.getFullYear();
  return (
    <>

      <ModalAniversario modalShow={modalShowAniversario} />
      {modalShowPesquisa ? <ModalPesquisa modalShowPesquisa={modalShowPesquisa} /> : ('')}
      {modalShowPesquisaSac ? <ModalPesquisaSac modalShowPesquisaSac={true} mensagemPesquisaSac={mensagemPesquisaSac} /> : ('')}


      <Modal
        show={modalShowRegulamento}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        backdropClassName="background-dialog"
        contentClassName="modal-css"
      >
        <Modal.Body className="pontos-participantes col-12 d-flex flex-column">
          <div className="col-10 col-lg-7 align-self-center text-center">
            <h3>Regulamento</h3>
            <strong>
              <p className="pt-3 col-12">
                Olá, você está prestes a navegar nas novidades da plataforma
                +top 2022.
              </p>
            </strong>
            <p>
              E, para dar início a sua experiência, você precisa dar o aceite no
              novo regulamento que conta com novas mecânicas.
            </p>
            <strong>
              <p className="pb-5">
                Vamos começar nossa jornada vitoriosa?
                <br />
                <br /> Juntos, somos +top.
              </p>
            </strong>
          </div>

          <div className="col-11 col-lg-7 align-self-center d-flex justify-content-center flex-column">
            <Form.Group className="align-self-start">
              <div className="d-flex text-left">
                <Form.Check
                  required
                  id="aceitoTermos"
                  checked={aceiteTermosDeUso}
                  onClick={() => {
                    !aceiteTermosDeUso
                      ? setAceiteTermosDeUso(true)
                      : setAceiteTermosDeUso(false);
                  }}
                />
                <Form.Label htmlFor="aceitoTermos" className="label-input ms-2">
                  Estou de acordo com a{" "}
                  <Link to="/politica">política de privacidade</Link>{" "}
                </Form.Label>
              </div>
            </Form.Group>

            <Form.Group className="align-self-start">
              <div className="d-flex">
                <Form.Check
                  required
                  id="aceitoTermosPrivacidade"
                  checked={politicasDePrivacidade}
                  onClick={() => {
                    !politicasDePrivacidade
                      ? setPoliticasDePrivacidade(true)
                      : setPoliticasDePrivacidade(false);
                  }}
                />

                <Form.Label
                  htmlFor="aceitoTermosPrivacidade"
                  className="label-input ms-2"
                >
                  Estou de acordo com os{" "}
                  <Link to="/regulamento">termos de uso</Link>{" "}
                </Form.Label>
              </div>
            </Form.Group>
            <Form.Group className="align-self-start">
              <div className="d-flex">
                <Form.Check
                  required
                  id="aceito-envio-sms"
                  checked={marketing}
                  onClick={() => {
                    !marketing ? setMarketing(true) : setMarketing(false);
                  }}
                />
                <Form.Label
                  htmlFor="aceito-envio-sms"
                  className="label-input ms-2"
                >
                  Estou de acordo com o envio de SMS, WhatApp e e-mail
                  marketing.
                </Form.Label>
              </div>
            </Form.Group>
          </div>
          <Button
            className="col-5 align-self-center mt-5 mb-5 btn-laranja"
            size="lg"
            onClick={() => {
              AceiteRegulamentoMarketingPoliticas();
            }}
            disabled={!aceiteTermosDeUso || !politicasDePrivacidade}
          >
            Continuar
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalShowAceiteVendas}
        onHide={handleClose}
        centered
        backdropClassName="background-dialog"
        contentClassName="modal-css"
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body className="pontos-participantes col-12 d-flex flex-column justify-center">
          <div className="col-11 col-lg-7 align-self-center text-center">
            <h3>Aceite Mensal</h3>
            <h5>
              {mm} / {yyyy}
            </h5>
            <strong>
              <p className="pt-3 col-12">Olá, tudo bem?</p>
            </strong>

            {user.cargo.descricao === "GESTOR DA INFORMACAO" ? (
              <p>
                Para receber as pontuações por envio das planilhas de
                funcionários e vendas, bônus trimestral, pontuação de
                aniversário, e outras pontuações extras deste mês, é necessário
                dar o aceite mensal de participação na mecânica do +top.
              </p>
            ) : (
              <p>
                Para receber as pontuações de vendas das categorias
                participantes, produtos SUPERTOPS, treinamentos, pontuação de
                aniversário, e outras pontuações extras deste mês, é necessário
                dar o aceite mensal de participação na mecânica do +top.
              </p>
            )}
            <strong>
              <p className="pb-5">
                NÃO VÁLIDO para campanhas.
                <br />
                <br /> Vamos fazer isso agora?{" "}
              </p>
            </strong>
          </div>
          <Button
            className="col-5 align-self-center mt-5 mb-5 btn-laranja"
            size="lg"
            onClick={() => {
              PontuacaoVendasAceitar();
            }}
          >
            Eu aceito
          </Button>
        </Modal.Body>
      </Modal>

      {!modalShowRegulamento && !modalShowAceiteVendas && dadosBannerComunicado ? <ModalComunicado dadosBannerComunicado={dadosBannerComunicado} /> : ''}



      <Home className="home-blackfriday">
        <NavBar user={user} natal={true} />
        <Container xs={12} className="p-0 mt-5 mb-5 container-conteudo-home" >
          <a className="whatsapp-icon" href="https://api.whatsapp.com/send?phone=551108007800606" target="_blank" rel="noreferrer">
            <i className="bi bi-whatsapp"></i>
          </a>
          <Row>
            <Col className="d-flex justify-content-center">
              <Fade in={modalErroCatalogo}>
                <Card
                  className="text-center p-3 m-2 z-index-top position-absolute"
                  bg="danger"
                  text="white"
                >
                  <Card.Body>
                    <Card.Title as="h2">Erro</Card.Title>
                    <Card.Text>{modalMsg}</Card.Text>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <Fade in={modalErro}>
                <Card
                  className="text-center p-3 m-2 z-index-top position-absolute"
                  bg="danger"
                  text="white"
                >
                  <Card.Body>
                    <Card.Title as="h2">Erro</Card.Title>
                    <Card.Text>{modalMensagem}</Card.Text>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
          </Row>
          <Row className="m-0">
            <Col
              lg={12}
              className="d-flex justify-content-around p-0 flex-wrap"
            >
              <Col lg={4} md={12} sm={12} xs={12} className="m-0 info-user-left">


                <Notificacao>
                  <div className={menuNotificacao}>
                    <div className={menuNotificacaoActive ? 'header-notificacoes' : 'oculto'}>
                      <span>Suas Notificações</span>
                      <span onClick={() => { ExibirNotificacao() }} className={menuNotificacaoActive ? 'close-button' : 'oculto'}> &#10006; </span>
                    </div>
                    <div className={menuNotificacaoActive ? '' : 'oculto'}>
                      {notificacoesUsuario ? notificacoesUsuario.map(({ id, mensagem, dataExibicao, assunto }, index) => (
                        <div className="card-notificacao" key={index}>
                          <p>{<span>
                            {dataExibicao.slice(8, 10) +
                              "/" +
                              dataExibicao.slice(5, 7) +
                              "/" +
                              dataExibicao.slice(0, 4)}
                          </span>}</p>
                          <p className="text-center stronger"><strong>{assunto}</strong></p>
                          <div>{parse(mensagem)}</div>
                          <p value={id} className="btn-marcar-lida" onClick={(e) => { NotificacaoLida(e.target.attributes.value.value, index) }}>Marcar como lida</p>
                        </div>
                      )) : ('')}
                    </div>
                  </div>
                </Notificacao>


                <ContainerLeft className="px-4 rounded m-0 pb-5 container-left">
                  <Row className="m-0">
                    <div className="d-flex justify-content-around  mt-5 ">
                      <div className="d-flex justify-content-center">
                        <div className="img-perfil-user">
                          <img src={fotoPerfil} alt="" />
                          {notificacoesUsuario.length > 0 ? <div className={menuNotificacaoActive ? 'oculto' : 'notificacao-icon'} onClick={() => { ExibirNotificacao() }}><p>{notificacoesUsuario.length}</p></div>
                            : ('')
                          }
                        </div>
                        <div className="nome-perfil-user">
                          <h3>Olá, {user.nome}</h3>
                          <p>
                            Seu nível atual:{" "}
                            <span>
                              {user.cargo.descricao !==
                                "GESTOR DA INFORMACAO" ? (
                                <span className="title-nivel-user">
                                  {data1 === 1 ? (
                                    <span className="nivel-usuario"> Explorador</span>
                                  ) : data1 === 2 ? (
                                    <span className="nivel-usuario">
                                      {" "}
                                      Visionário
                                    </span>
                                  ) : data1 === 3 ? (
                                    <span className="nivel-usuario">
                                      {" "}
                                      Realizador
                                    </span>
                                  ) : data1 === 4 ? (
                                    <span className="nivel-usuario">
                                      {" "}
                                      Conquistador
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              ) : (
                                <div className="d-flex justify-content-between mb-2">
                                  <span className="title-nivel-user">
                                    {data1 === 1 ? (
                                      <span className="nivel-usuario gi">
                                        {" "}
                                        1 Estrela
                                      </span>
                                    ) : data1 === 2 ? (
                                      <span className="nivel-usuario">
                                        {" "}
                                        2 Estrelas
                                      </span>
                                    ) : data1 === 3 ? (
                                      <span className="nivel-usuario">
                                        {" "}
                                        3 Estrelas
                                      </span>
                                    ) : data1 === 4 ? (
                                      <span className="nivel-usuario">
                                        {" "}
                                        4 Estrelas
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="icone-config mt-4">
                        <Link to="/meu-perfil">
                          <i className="bi bi-gear icon-large"></i>
                        </Link>
                      </div>
                    </div>
                  </Row>
                  <Row className="m-0">
                    <div className="container-saldo-user mt-5 rounded px-4">
                      <div className="d-flex justify-content-between">
                        <p className="mt-3 mb-0">Pontuação atual</p>
                        <span className="pt-1">
                          <i className={changeEye} onClick={toggleSaldo}></i>
                        </span>
                      </div>
                      <div className="d-flex mb-2 align-items-center">
                        {saldoShow ? (
                          <span className="fw-bold font-saldo">
                            {saldoValor}
                          </span>
                        ) : (
                          <div className="valor-pontos rounded"></div>
                        )}
                      </div>
                    </div>
                  </Row>
                  <Row className="m-0">
                    <div className="container-resgatar-extrato d-flex justify-content-between p-0 pt-4 pb-4">
                      <Link
                        to=""
                        className="btn-resgatar w-100 me-3 btn-laranja"
                        onClick={abreAbaPremios}
                      >
                        Resgatar
                      </Link>
                      <Link
                        to="/extrato"
                        className="btn-extrato w-100 btn-verde"
                      // onClick={extratoDesabilitado}
                      >
                        Extrato
                      </Link>
                    </div>
                    {user.cargo.descricao !== "GESTOR DA INFORMACAO" ? (
                      <Link
                        variant="outline-secondary"
                        className="btn-convide-amigo btn btn-outline-secondary w-100 d-flex align-items-center justify-content-center btn-height-3"
                        to="/convide-um-amigo"
                      >
                        Convide um amigo
                      </Link>
                    ) : (
                      <Link
                        to="/planilhas"
                        className="btn btn-outline-secondary w-100 d-flex align-items-center justify-content-center btn-height-3 publicar-planilha-gi"
                      >
                        Publicar Planilha
                      </Link>
                    )}
                  </Row>
                  {user.lojas[0].rede.nome !== 'J MAHFUZ LTDA' 
                  && user.lojas[0].rede.nome !== 'CONDOR SUPER CENTER LTDA' 
                  && user.lojas[0].rede.nome !== 'GLOBAL DISTRIBUIDORA DE BENS DE CONSUMO LTDA' 
                  && user.lojas[0].rede.nome !== 'LOJAS GUAIBIM' 
                  && user.lojas[0].rede.nome !== 'RAMIRO CAMPELO COMERCIO DE UTILIDADES LTDA'
                  && user.lojas[0].rede.nome !== 'MASTER ELETRÔNICA DE BRINQ. LTDA.'
                  && user.lojas[0].rede.nome !== 'MILLENA COMERCIO VAREJISTA DE MÓVEIS E ELETRO EIRELI'
                  && user.lojas[0].rede.nome !== 'ELETROMAR MOVEIS ELETRODOMESTICOS LTDA'
                  ?
                    <Row className="mt-4 m-0 container-conquistas mt-5">
                      <ConquistasUsuarioHome
                        user={user}
                        childToParent={childToParent}
                      />
                    </Row>
                    : ('')}
                </ContainerLeft>
              </Col>
              <Col lg={7} md={12} sm={12} xs={12}>
                <ContainerRight className="rounded container-right">
                  <Slider2>
                    <Carousel controls={controls}>
                      {bannerSlide ?
                        bannerSlide.map(
                          ({ urlImagem, url }, index) => (
                            <Carousel.Item key={index}>
                              <a href={url}>
                                <img
                                  className="d-block w-100"
                                  src={urlImagem}
                                  alt="First slide"
                                  style={{ cursor: "pointer" }}
                                /></a>
                              <p>{bannerSlide.urlImagem}</p>
                            </Carousel.Item>
                          )
                        ) : ('')}

                    </Carousel>
                  </Slider2>
                  <div className="d-flex topnews">
                    <SuperTops className="rounded mt-1 supertop-bf">
                      <div className="d-flex justify-content-between">
                        <h3>Supertops</h3>
                        <p>
                          <span>
                            {mesAtual}/{anoAtual}
                          </span>
                        </p>
                      </div>
                      <div className="d-flex justify-content-around container-supertop">
                        {produtosSuperTops.length !== 0 &&
                          produtosSuperTops.map(
                            (
                              {
                                imagem,
                                produto: {
                                  sku,
                                },
                              },
                              index
                            ) => (
                              <div className="supertop-item rounded">
                                <div className="d-flex justify-content-center flex-column">
                                  <div className="d-flex justify-content-center">
                                    <div className="posicao-premiacao">
                                      {index + 1}
                                    </div>
                                    <img
                                      src={imagem}
                                      alt=""
                                      className="img-supertops"
                                    />
                                  </div>
                                  <div className="codigo-super-top">
                                    <h2 className="m-0">{sku}</h2>
                                    <div className="super-top-pontos">
                                      20 pontos
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </SuperTops>

                    <TopNews id="topnews" className="mt-1">
                      {listaPost?.length > 0 ?
                        <>
                          <Link to="/top-news" className="link">
                            <div to="/top-news" className="overlay" >
                              <span className="overlay-text" >Clique e veja mais</span>
                            </div>
                          </Link>
                          <div to="/top-news" className="link">
                            <div className="container-slide">
                              <Slider2 >
                                <Carousel controls={controls} interval={1000} slidestoshow={'3'}>
                                  {listaPost ?
                                    listaPost.map((item, index) => (
                                      <Carousel.Item key={index}>
                                        <img
                                          key={index}
                                          src={item.urlThumbnail}
                                          alt={item}
                                        />
                                      </Carousel.Item>

                                    ))
                                    : ''
                                  }

                                </Carousel>
                              </Slider2>
                              <div className="link-topnews me-3">
                                <Link to="/top-news">
                                  <p>Ver mais</p>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </>
                        : ('')}
                    </TopNews>

                  </div>
                  {user.lojas[0].rede.nome !== 'J MAHFUZ LTDA' && produtosPremium.length ?
                    <ProdutosPremium className="rounded mt-5 produtos-premium">
                      <div className="d-flex justify-content-between">
                        <h3>Premium</h3>
                        <p>
                          <span>
                            {mesAtual}/{anoAtual}
                          </span>
                        </p>
                      </div>

                      <Slider {...settings2}>
                        {produtosPremium.length !== 0 &&
                          produtosPremium.map(
                            (
                              {
                                imagem,
                                // pontuacao,
                                produto: {
                                  sku,
                                },
                              },
                              index
                            ) => (
                              <div className="supertop-item rounded">
                                <div className="d-flex justify-content-center">
                                  <div>
                                    <div className="posicao-premiacao">
                                      {index + 1}
                                    </div>
                                    <img
                                      src={imagem}
                                      alt=""
                                      className="img-supertops"
                                    />
                                  </div>
                                  <div className="codigo-super-top">
                                    <h2 className="m-0">{sku}</h2>
                                    <div className="super-top-pontos">
                                      12 pontos
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </Slider>
                    </ProdutosPremium>
                    : ('')}
                  <ProdutosParticipantes className="my-5 rounded">
                    <div className="container-produtos-participantes">
                      <div className="p-5">
                        <div className="d-flex justify-content-between titulo-slides">
                          <h3>Categorias participantes</h3>
                        </div>
                        <Slider {...settings}>
                          {categoriasParticipantes.length !== 0 &&
                            categoriasParticipantes.map(
                              ({ descricao, imagem, pontuacao }, index) => (
                                <div key={index}>
                                  <div className="box-categoria-participante">
                                    <p className="d-flex justify-content-center img-categoria-premios">
                                      <img src={imagem} alt="" />s
                                    </p>
                                    <p className="nome">
                                      {primeiraLetraMaiuscula(descricao)}
                                    </p>
                                    <p className="pontos-participantes">
                                      {Math.trunc(
                                        pontuacao.toString().replace(",", ".")
                                      )}{" "}
                                      {pontuacao.toString().replace(",", ".") >
                                        1
                                        ? "pontos"
                                        : "ponto"}
                                    </p>
                                  </div>
                                </div>
                              )
                            )}
                        </Slider>
                      </div>
                    </div>
                  </ProdutosParticipantes>

                </ContainerRight>
              </Col>
              {/* <Link to="/">Home</Link> */}
            </Col>
          </Row>
        </Container>
        <Footer className="m-0 p-0"></Footer>
        {/* <NeveEfeito /> */}
      </Home>
    </>
  );
}

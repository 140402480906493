import styled from "styled-components";

export const MeusDadosCampos = styled.div`
  background: #efeeed;
  .formato-arquivo{
    position: absolute;
    span{
      margin-top: 23px;
    }
    .btn-container{
      margin-top: 19px;
      justify-content: center;

      div{
          margin: 0px 19px;
        }
    }
  }
  .btn-desk-export{
    margin-top: 12.3rem!important;
    .aviso-senha{
      top: -5rem!important;
    }
  }
  .width-export-btn{
    margin-top: 5rem;
  }
  .btn-mobile-export{
    position: relative;
    margin-top: 12rem;
    .aviso-senha{
      position: absolute;
      color: red;
      width: 300px;
      top:-5.5rem;
      left:9vw;
    }
  }

  .check-tipo-pdf{
    position: absolute;
    top: 123px;
    .form-check-input:checked{
      background-color: var(--laranja);
      border-color: var(--laranja);
    }
  }
  .container-img-prev {
    margin-bottom: 16vh;
    /* margin-bottom: 30px; */
  }
  .img-prev {
    position: absolute;
    height: 12rem;
    border-radius: 100%;
    @media (max-width: 992px) {
      height: 10rem;
    }
    @media (min-width: 768px) {
      /* left: 10%; */
    }
  }
  .input-img {
    margin: 0;
    height: 12rem;
    cursor: pointer;
    position: absolute;
    opacity: 0;
    left: 0;
    input {
      height: 12rem;
    }
  }
  @media (max-width: 768px) {
    padding-top: 0;
    h4 {
      text-align: center;
      margin-top: 2rem;
    }
  }
  .img-invalid {
    position: absolute;
    top: 12rem;
  }
  .formatos-imgs {
    margin-top: 13rem;
    position: absolute;
    width: 100%;
    @media (max-width: 992px) {
      margin-top: 11rem;
    }
  }

  .container-campos {
    @media (min-width: 768px) {
      margin-left: 50px;
    }
    .titulo-page {
      padding-bottom: 34px;
      h2 {
        @media (max-width: 768px) {
          /* margin-top: 17rem; */
        }
        // margin-top: 105px;
        /* margin-bottom: 35px; */
      }
      span {
        color: #989898;
        font-size: 14px;
      }
      @media (max-width: 992px) {
        text-align: center;
      }
    }
    .select-perguntas {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      max-width: 332px;
      @media (max-width: 768px) {
        min-width: 270px;
      }
    }
    .info-senha {
      font-size: 14px;
      color: #313131;
      margin-bottom: 35px;
    }
    .checkbox-regulamentos {
      font-size: 12px;
      margin-left: 25px;
      a {
        color: #58595b;
      }
    }
    .busca-cep {
      position: absolute;
      right: 0;
      top: -2rem;
    }
    .pass-hide-cadastro {
      position: absolute;
      font-size: 1.2rem;
      top: 2.4rem;
      right: 2rem;
    }
  }
  .img-usuario {
    margin-top: 212px;
    margin-right: 60px;
    @media (max-width: 768px) {
      margin: 0;
      margin-bottom: 70px;
    }
  }
  .container-principal {
    @media (max-width: 768px) {
      justify-content: center;
    }
  }
  .navbar-meus-dados {
    //  display:none!important;
  }
  .titulo-page {
    display: block;
    @media (max-width: 992px) {
      display: none;
    }
  }
  .titulo-page-mobile {
    display: none;
    @media (max-width: 992px) {
      display: block;
      margin-top: 23px;
      margin-bottom: 50px;
    }
  }
  .align-inputs {
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
  .titulo-meu-perfil {
    display: none;
    @media (max-width: 992px) {
      display: block;
    }
  }
  .width-export-btn{
    width: 21rem;
    position: relative;
    .aviso-senha{
      position: absolute;
      color: red;
      width: 700px;
      top:50px;
      left: -180px;
    }
  }
  
`;
export const FooterStyle = styled.div`
  margin-right: -15px;
`;
export const BodyModal = styled.div`
  background: var(--background-escuro);
  height: 100vh;

  .info-telefone {
    font-size: 14px;
    color: #fff;
  }
  .body-card {
    @media (min-width: 992px) {
      width: 28rem;
    }
  }
  .icon-size {
    font-size: 4rem;
  }
`;
export const MenuChange = styled.div`
  /* position: absolute; */
  .list-group {
    background: white;
    border-radius: 5px;
    padding-bottom: 30px;
  }
  .list-group-item {
    border: none;
    cursor: pointer;
  }
  .list-group-item.active {
    background-color: #fff;
    color: green;
    border-left: 4px solid green;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
  }
  @media (max-width: 992px) {
    display: none;
  }
`;

export const ButtoesNavMobile = styled.div`
  display: flex;
  justify-content: center;
  max-width: 90vw;
  overflow-x: auto;
  font-size: 9px;
  font-weight: bold;
  @media (min-width: 992px) {
    display: none;
  }
  .rowCarrossel{
    flex-wrap:nowrap;
    width: 26rem;
  }
  .box {
    font-weight: normal;
    font-size: 1rem;
    height: 7rem;
    min-width: 32vw;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
    border-radius: 5px;
    background: #fff;
    padding: 0.5rem;
  }
  .box.active {
    background: var(--verde);
    color: white;
  }
`;

export const PorcentagemDados = styled.div`
  h2 {
    color: var(--laranja);
  }
  .container-porcentagem-dados {
    @media (max-width: 992px) {
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      .item-aceites,
      .item-nao-aceite {
        margin-top: 1rem;
      }
      .item-aceites-gerais {
        width: 100% !important;
        margin-top: 1rem;
      }
      h1 {
        font-size: 40px;
      }
    }

    .item-aceites,
    .item-nao-aceite,
    .item-aceites-gerais {
      background: #fff;
      text-align: center;
      padding: 1.2vh 0px;
      border-radius: 5px;
      h1 {
        color: var(--laranja);
      }
    }
    .item-aceites {
      margin-top: 1rem;
      span{
        padding: 0.5rem;
      }
    }
    .item-aceites-ativos {
      height: 100%;
      background: #fff;
      text-align: center;
      padding: 1.2vh 0px;
      border-radius: 5px;
      h1 {
        color: var(--laranja);
        font-size: 5rem;
      }
    }
  }
  .task-card-container {
    position: relative;
    border-radius: 5px;

    .progressBar {
      margin-bottom: 26px;
      margin-bottom: 1.66em;
      position: relative;
    }

    .progressBarcontainer {
      width: 100%;
      height: 5px;
      background: #e2e2e2;
      overflow: hidden;
      border-radius: 5px;
    }

    .progressBarValue {
      height: 5px;
      float: left;
      border-radius: 5px;
      background: #9ed530;
    }

    .progressBarValueGerenteDeLoja {
      height: 5px;
      float: left;
      border-radius: 5px;
      background: #fbc81e;
    }

    .speech-bubble {
      font-size: 0.75em;
      line-height: 2em;
      position: absolute;
      top: -0.6em;
      text-align: center;
      min-width: 3em;
      border-radius: 0.3em;
      color: white;
      display: none;
    }

    .speech-bubble:after {
      border: 0.5em solid transparent;
      content: "";
      margin-left: -0.5em;
      position: absolute;
      bottom: -50%;
      left: 50%;
    }

    .html {
      left: calc(88.5% - 1.5em);
    }

    .html:after {
      border-top-color: #a487b2;
    }

    .progress-container {
      position: relative;
    }

    .speech-bubble {
      font-size: 0.75em;
      line-height: 2em;
      position: absolute;
      top: -0.6em;
      text-align: center;
      min-width: 3em;
      border-radius: 0.3em;
      color: white;
      display: none;
    }

    .niveis-progressbar {
      display: flex;
      position: absolute;
      width: 100%;
      justify-content: space-between;
      top: -10px;
      div {
        &.nivel-alcancado {
          filter: hue-rotate(var(--value, 360deg));
          --value: 363deg;
        }
      }

      .icone-raking {
        width: 20px;
      }

      p {
        font-size: 12px;
        width: 60px;
        margin-top: 5px;
        margin-right: -10px;
      }
      img {
        &.nivel-alcancado {
          filter: hue-rotate(var(--value, 360deg));
          --value: 363deg;
        }
      }
    }
    .text-conquista-gi {
      margin-bottom: -21px;
      margin-top: 20px;
      color: #989898;
    }

    .descricao-niveis {
      font-size: 12px;
      margin-left: 12px;
      margin-top: -8px;
      position: relative;
      top: 25px;
      left: -10px;
    }

    .title-nivel-user {
      margin-bottom: 12px;
      font-size: 12px;
      @media (max-width: 370px) {
        font-size: 10px;
      }
      // margin-bottom: 16px;
      .nivel-usuario {
        color: #9ed530;
        &.gi {
          color: #000;
          font-weight: 700;
        }
      }

      .nivel-atual {
        color: #989898;
      }

      .qtdEstrelas {
        color: #000;
      }
    }
  }
`;

export const CamposBusca = styled.div`
  .form-mobile {
    @media (max-width: 992px) {
      margin-top: 1rem;
    }
  }
  .form-flex {
    display: flex;
    @media (max-width: 992px) {
      display: block;
      text-align: center;
    }
  }
  .input-mes-ano {
    width: 15rem;
    height: 45px;
    font-size: 12px;
    padding: 14px 11px;
    border-radius: 5px;
    border: 1px solid #000;
    margin-right: 30px;
    margin-top: 30px;
    @media (max-width: 992px) {
      display: inline;
      margin: 0px 2px;
      width: 47%;
      margin-bottom: 20px;
    }
  }
  .input-busca-nome {
    width: 35vw;
    height: 45px;
    border: 1px solid #000;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  .input-selec-revendas {
    width: 19.5vw;
    margin-right: 3vw;
    border: 1px solid #000;
    height: 45px;
    font-size: 0.8rem;
    @media (max-width: 992px) {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .btn-exportar {
    width: 18rem;
    height: 45px;
    align-self: center;
    margin-left: 27px;
    margin-top: 32px;
    @media (max-width: 992px) {
      width: 100%;
      height: 45px;
      margin-left: 0px;
    }
  }
  .btn-buscar {
    width: 18rem;
    height: 45px;
    margin-top: 30px;
    @media (max-width: 992px) {
      width: 100%;
      height: 45px;
    }
  }
`;

export const CamposBuscaTreinamento = styled.div`
  .form-mobile {
    @media (max-width: 992px) {
      margin-top: 1rem;
    }
  }
  .form-flex {
    display: flex;
    @media (max-width: 992px) {
      display: block;
      text-align: center;
    }
  }
  .input-mes-ano {
    width: 15rem;
    height: 45px;
    font-size: 0.8rem;
    padding: 14px 11px;
    border-radius: 5px;
    border: 1px solid #000;
    margin-right: 30px;
    margin-top: 30px;
    @media (max-width: 992px) {
      display: inline;
      margin: 0px 2px;
      width: 47%;
      margin-bottom: 20px;
    }
  }
  .input-busca-nome {
    width: 35vw;
    height: 45px;
    border: 1px solid #000;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  .input-selec-revendas {
    width: 19.5vw;
    margin-right: 3vw;
    border: 1px solid #000;
    height: 45px;
    font-size: 0.8rem;
    @media (max-width: 992px) {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .btn-exportar {
    width: 18rem;
    height: 45px;
    align-self: center;
    margin-left: 27px;
    margin-top: 32px;
    @media (max-width: 992px) {
      width: 100%;
      height: 45px;
      margin-left: 0px;
    }
  }
  .btn-buscar {
    width: 18rem;
    height: 45px;
    margin-top: 30px;
    @media (max-width: 992px) {
      width: 100%;
      height: 45px;
    }
  }
`;

export const CamposBuscaVendas = styled.div`
  .form-mobile {
    @media (max-width: 992px) {
      margin-top: 1rem;
    }
  }
  .form-flex {
    display: flex;
    @media (max-width: 992px) {
      display: block;
      text-align: center;
    }
  }
  .input-mes-ano {
    width: 15rem;
    height: 45px;
    font-size: 0.8rem;
    padding: 14px 11px;
    border-radius: 5px;
    border: 1px solid #000;
    margin-right: 30px;
    margin-top: 30px;
    @media (max-width: 992px) {
      display: inline;
      margin: 0px 2px;
      width: 47%;
      margin-bottom: 20px;
    }
  }
  .input-busca-nome {
    width: 35vw;
    height: 45px;
    border: 1px solid #000;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  .input-selec-revendas {
    font-size: 0.8rem;
    width: 19.5vw;
    margin-right: 3vw;
    border: 1px solid #000;
    height: 45px;
    @media (max-width: 992px) {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .btn-exportar {
    width: 18rem;
    height: 45px;
    align-self: center;
    margin-left: 27px;
    margin-top: 32px;
    @media (max-width: 992px) {
      width: 100%;
      height: 45px;
      margin-left: 0px;
    }
  }
  .btn-buscar {
    width: 18rem;
    height: 45px;
    margin-top: 30px;
    @media (max-width: 992px) {
      width: 100%;
      height: 45px;
    }
  }
`;

export const TabelaResultado = styled.div`

  margin-top: 2rem;
  .lista-chamados-container {
    .botoes-filtro {
      background: #fff;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      height: 45px;
      align-items: center;
      border-radius: 5px;
      button {
        width: 48.5%;
        height: 40px;
        margin: 0 3px;
      }
      button:hover {
        background: #cdcdcd;
      }
    }
    .header-table {
      background: #000;
      color: #fff;
      border-radius: 5px;
      padding-right: 1rem;
      @media (max-width: 992px) {
        padding-right: 0;
      }

      .items-header {
        p {
          font-size: 0.8rem;
          margin: 0;
          padding: 10px;
        }

        .w30 {
          width: 30%;
        }
        .w40 {
          width: 40%;
        }
      }
    }
    .item-historico {
      margin: 2px 0;
      background: #fff;
      border-bottom:1px solid #eee;
      p {
        font-size: 0.8rem;
        margin: 0;
        padding: 10px;
      }

      .w35 {
        width: 30%;
      }
      .w40 {
        width: 40%;
      }
    }
    .item-historico:hover {
      background: #cdcdcd;
      cursor: pointer;
    }
    .container-items {
      max-height: 360px;
      overflow-x: auto;
      ::-webkit-scrollbar {
  width: 6px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #eee;        /* color of the tracking area */
  border-radius: 20px;
}
 
::-webkit-scrollbar-thumb {
  background-color: orange;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb 
  border-left: 3px solid white;
  border-right: 3px solid white; */ /* creates padding around scroll thumb */
}
    }
  }
`;

export const ProgressoNivel = styled.div`
  background: #efeeed;
  .nivel-atual-user {
    background: #fff;
    border: 1px solid #e2e2e2;
  }
  .nivel-atual-user,
  .task-card-container {
    position: relative;
    padding: 13px;
    border-radius: 5px;

    .progressBar {
      margin-bottom: 26px;
      margin-bottom: 1.66em;
      position: relative;
    }

    .progressBarcontainer {
      width: 100%;
      height: 5px;
      background: #e2e2e2;
      overflow: hidden;
      border-radius: 5px;
    }

    .progressBarValue {
      height: 5px;
      float: left;
      border-radius: 5px;
      background: #9ed530;
    }

    .progressBarValueGerenteDeLoja {
      height: 5px;
      float: left;
      border-radius: 5px;
      background: #fbc81e;
    }

    .speech-bubble {
      font-size: 0.75em;
      line-height: 2em;
      position: absolute;
      top: -0.6em;
      text-align: center;
      min-width: 3em;
      border-radius: 0.3em;
      color: white;
      display: none;
    }

    .speech-bubble:after {
      border: 0.5em solid transparent;
      content: "";
      margin-left: -0.5em;
      position: absolute;
      bottom: -50%;
      left: 50%;
    }

    .html {
      left: calc(88.5% - 1.5em);
    }

    .html:after {
      border-top-color: #a487b2;
    }

    .progress-container {
      position: relative;
    }

    .speech-bubble {
      font-size: 0.75em;
      line-height: 2em;
      position: absolute;
      top: -0.6em;
      text-align: center;
      min-width: 3em;
      border-radius: 0.3em;
      color: white;
      display: none;
    }

    .niveis-progressbar {
      display: flex;
      position: absolute;
      width: 100%;
      justify-content: space-between;
      top: -10px;
      div {
        &.nivel-alcancado {
          filter: hue-rotate(var(--value, 360deg));
          --value: 363deg;
        }
      }

      .icone-raking {
        width: 20px;
      }

      p {
        font-size: 12px;
        width: 60px;
        margin-top: 5px;
        margin-right: -10px;
      }
      img {
        &.nivel-alcancado {
          filter: hue-rotate(var(--value, 360deg));
          --value: 363deg;
        }
      }
    }
    .text-conquista-gi {
      margin-bottom: -21px;
      margin-top: 20px;
      color: #989898;
    }

    .descricao-niveis {
      font-size: 12px;
      margin-left: 12px;
      margin-top: -8px;
      position: relative;
      top: 25px;
      left: -10px;
    }

    .title-nivel-user {
      margin-bottom: 12px;
      font-size: 12px;
      @media (max-width: 370px) {
        font-size: 10px;
      }
      // margin-bottom: 16px;
      .nivel-usuario {
        color: #fbc81e;
        &.gi {
          color: #000;
          font-weight: 700;
        }
      }

      .nivel-atual {
        color: #989898;
      }

      .qtdEstrelas {
        color: #000;
      }
    }
  }
  .ft-12 {
    font-size: 12px;
  }
`;
export const Etapas = styled.div`
  /* display:none; */
  .fundo-branco {
    background: #fff;
  }
  .icon-task {
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    display: grid;
    justify-content: center;
    align-content: center;
    padding: 0;
    margin: 0;
    height: 50px;
    width: 52px;
  }
  .task-section {
    .task-card-container {
      background: #fff;
      margin-top: 18px;
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      padding: 0px;

      .progressBarcontainer {
        width: 100%;
        height: 5px;
        background: #e2e2e2;
        overflow: hidden;
        border-radius: 5px;
        margin-bottom: 6px;
      }

      .progressBarValue {
        height: 5px;
        float: left;
        border-radius: 5px;
        background: $laranja;
      }

      .task-card-conteudo {
        display: flex;
        padding: 15px;

        .task-info {
          height: 60px;
          width: 100%;
          // display: flex;
          .task-text {
            display: flex;
            justify-content: space-between;
            margin-left: 12px;
            height: 21px;

            .task-name {
              color: $laranja;
              font-weight: bolder;
              font-size: 14px;
            }

            .task-data {
              font-size: 12px;
              margin-top: 3px;
              color: #989898;
            }
          }

          .task-sinopse {
            font-size: 12px;
            margin-left: 12px;
            color: #989898;
          }

          .task-nivel {
            margin-left: 12px;
            display: flex;
          }
          .task-nivel-circulo {
            width: 12px;
            height: 12px;
            background: #e2e2e2;
            margin-right: 7px;
            border-radius: 100%;
            margin-bottom: 5px;
            &.completado {
              background: #9ccb3d;
            }
            &.faltou {
              background: red;
            }
          }
        }
      }
      .progressBar {
        margin-bottom: 0px;
      }
    }
  }
`;

import styled from 'styled-components'

export const FooterComponente = styled.div`
    .footer{
        z-index: 1;
	position: sticky;
       // position: absolute;
        /* right: 0; */
        bottom: 0;
        left: 0;
        /* padding: 1rem; */
        background-color: #FBFBFB;
        text-align: center;
        height: 148px;  
        width: 100%;
        @media (max-width: 768px) { 
            height: 190px;
             width: px;
            /* margin-right: -12px; */
         }
        img{
            width: 108px;
            height: 45px;
            margin-right: 90px;
        }
        a{
            color: #000;
        }
    }
    .container-itens-nav{
        justify-content: center;
        @media (max-width: 768px) { 
            flex-direction: column
        }
        .icon-footer{
            @media (max-width: 768px) { 
                display: flex;
                justify-content: center;
                    img{
                        margin-right: 0;
                    }
            }
           
        }
    }
`
